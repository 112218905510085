.myaccount_cmodal-container {
}

.myaccount_cmodal-container .modal-body {
  background: #09091d;
  box-shadow: 0px 0px 12px 0px white;
  border-radius: 7px;
  padding: 12px 30px 0px 30px !important;
  height: calc(90vh - 30px);
  overflow: auto;
}

.myaccount_cmodal-container .modal-body::-webkit-scrollbar {
  width: 4px;
}

.myaccount_cmodal-container .modal-body::-webkit-scrollbar-track {
  border-radius: 20px !important;
  background: #f1f1f1;
}

.myaccount_cmodal-container .modal-body::-webkit-scrollbar-thumb {
  border-radius: 20px !important;
  background: #f05436;
}

.myaccount_cmodal-container .modal-body::-webkit-scrollbar-thumb:hover {
  border-radius: 20px !important;
  background: #555;
}

.myaccount_cmodal-container .modal-title {
  margin: 0 auto 10px auto;
  font-size: 18px;
  color: white;
  font-family: 'Inter', sans-serif;
  letter-spacing: 2px;
  font-weight: 500;
}

.myaccount_cmodal-form-wrapper {
  display: flex;
  gap: 20px;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.myaccount_cmodal-form-group {
  width: 80%;
}

.myaccount_cmodal-form-input {
  padding: 8px;
  border-radius: 5px;
}

.myaccount_cmodal-form-input-error {
  position: absolute;
  display: block;
  justify-content: center;
  text-align: left;
  color: #f05436;
  font-size: 10px;
  position: absolute;
  left: 33px;
}

.myaccount_cmodal-form-button {
  background-color: #f05436;
  border-radius: 5px;
  width: 20%;
  font-size: 16px;
  height: 38px;
  color: white;
  border: 0;
  font-weight: 900;
  margin: auto;
  justify-content: center;
  display: block;
}

.myaccount_cmodal-collaborator-list-title {
  font-size: 17px;
  color: white;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
  line-height: 26px;
  margin-top: 8px;
}

.myaccount_cmodal-collaborator-list {
  margin-top: 20px;
  display: grid;
  gap: 10px;
}

.myaccount_cmodal-collaborator-list-spinner {
  text-align: center;
  padding: 20px;
}

.myaccount_cmodal-collaborator-list-placeholder {
  text-align: center;
  padding: 50px;
}

.myaccount_cmodal-collaborator-list-placeholder h4 {
  font-size: 17px;
  color: #f05436;
  font-family: 'Inter', sans-serif;
  line-height: 26px;
  margin-top: 8px;
}

.myaccount_cmodal-collaborator-list-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 20px;
  color: black;
  padding: 0.475rem 0.75rem;
  font-size: 14px;
  border: 0;
  width: 100%;
  box-shadow: rgb(240, 84, 54) 0px 0px 7px 0px;
}

.myaccount_cmodal-collaborator-list-card h6 {
  color: white;
  text-align: left;
  align-items: center;
  font-size: 15px;
  font-weight: 700;
  margin-top: 5px;
  line-height: 20px;
  overflow-wrap: break-word;
}

.myaccount_cmodal-collaborator-list-card button {
  border: none;
  background: none;
  color: white;
  letter-spacing: 3px;
  font-weight: 600;
  text-align: left;
  margin-left: 10px;
}
