.maap-comments-wrapper {
  height: 100%;
  padding: 30px 30px;
  position: relative;
  overflow: hidden;
}

.maap-comments-spinner-wrapper {
  background-color: #09091dbc;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.maap-comments-spinner {
  font-size: 30px;
  height: 50px;
  width: 50px;
  z-index: 2;
  cursor: pointer;
  color: #f05436;
}

.maap-comments-wrapper h4 {
  color: #f05436;
  font-size: 26px;
  padding: 0 10px;
  display: flex;
}

.maap-comments-list {
  display: flex;
  flex-direction: column;
  gap: 25px;
  height: 100%;
  overflow-y: auto;
  padding: 20px 10px 30px 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.maap-comments-list::-webkit-scrollbar {
  display: none;
}

.maap-comments-creation-card {
  box-shadow: 0px 0px 8px white;
  padding: 10px 20px;
  border-radius: 15px;
  width: 100%;
  cursor: pointer;
}

.maap-comments-creation-form {
  display: grid;
  gap: 10px;
}

.maap-comments-creation-form-header {
  position: relative;
  display: flex;
  gap: 6px;
}

.maap-comments-creation-form-header p {
  color: #f05436;
  font-size: 16px;
  margin: auto 0;
}

.maap-comments-creation-add-button {
  display: flex;
  gap: 10px;
}

.maap-comments-creation-add-button p {
  color: #f05436;
  font-size: 16px;
  margin: auto 0;
}

.maap-comments-creation-form-header {
  position: relative;
  display: flex;
  gap: 6px;
}

.maap-comments-title-input {
  color: white;
  font-size: 16px;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  background: #09091d;
  border: unset;
  width: calc(100% - 50px) !important;
  padding: 0 !important;
  margin: 0 !important;
  outline: none !important;
}

.maap-comments-title-input:focus-visible {
  outline: none !important;
}

.maap-comments-creation-form-action-button {
  display: flex;
  gap: 6px;
}
