@import 'bootstrap/dist/css/bootstrap.min.css';

.responsive_contact_content {
  width: 33%;
  margin-top: 20px;
  justify-content: start;
  align-items: left;
  margin: auto;
}

.contct_heading {
  font-size: 48px;
  color: #f05436;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
}

.contact_form_label {
  color: white;
  margin: 0px !important;
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 10px;
}

.Error_msg {
  color: #f05436;
  font-size: 15px !important;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  margin-top: 20px;
}

.contact_page_form_main p {
  font-size: 18px;
  color: white;
  margin-bottom: 0px;
}
.contact_page_form_main {
  font-size: 18px;
  color: white;
  margin-bottom: 10px;
}

.font_contact_Weight500 {
  font-weight: 500;
}

.margin_contact_10 {
  margin-top: -10px;
}

.input_contact {
  border-radius: 10px;
  color: black;
  padding: 0.375rem 0.75rem;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 14px;
  border: 0;
  width: 100%;
}

.contact_button {
  background-color: #f05436;
  border-radius: 10px;
  width: 70%;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  border: 0;
  font-weight: 900;
  margin: auto;
  justify-content: center;
  display: block;
  margin-top: 18px;
}

.contact_submitButton {
  background-color: #f05436;
  border-radius: 10px;
  width: 100%;
  font-size: 17px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  font-weight: 700;
  /* margin-top: 2rem; */
  border: 1px solid #f05436;
}

.contact_submitButton:hover {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  font-weight: 800;
  background-color: #09091d;
  color: #f05436;
}

@media screen and (max-width: 1400px) {
  .responsive_contact_content {
    /* width: 70%; */
    justify-content: center;
    margin: auto;
  }
}

@media screen and (max-width: 1200px) {
  .responsive_contact_content {
    /* width: 50%; */
    justify-content: center;
    margin: auto;
  }
}

@media screen and (max-width: 800px) {
  .responsive_contact_content {
    width: 70%;
  }
}
