@import 'bootstrap/dist/css/bootstrap.min.css';

.posterImgDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.posterImg {
  width: 450px !important;
  height: 450px !important;
}

@media only screen and (max-width: 900px) {
  .posterImg {
    width: 370px;
    height: 370px;
  }
}

@media only screen and (max-width: 767px) {
  .posterImgDiv {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .posterImg {
    width: 400px;
    height: 400px;
  }
}

@media only screen and (max-width: 550px) {
  .posterImg {
    width: 330px;
    height: 330px;
  }
}

@media only screen and (max-width: 350px) {
  .posterImg {
    width: 240px;
    height: 240px;
  }
}
@media only screen and (max-width: 1420px) {
  .posterImg {
    width: 450px !important;
    height: 450px !important;
  }
}
@media only screen and (min-width: 1400px) {
  .posterImg {
    width: 530px !important;
    height: 490px !important;
  }
}
