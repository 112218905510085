@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Poppins:ital,wght@0,600;1,300;1,500;1,600;1,700&display=swap');

.baner_about_heading {
  font-size: 48px;
  color: #f05436;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
  margin-top: 85px;
  text-align: center;
}

.width_about_45 {
  width: 45%;
}

.width_about_60 {
  width: 60%;
}

.width_about_image {
  width: 650px !important;
}

.width_about_70 {
  width: 62%;
  margin-top: 8px;
}

.text-class {
  color: white;
  font-size: 16px;
  font-weight: 600;
}

.list-items {
  width: 62%;
  text-decoration: 'none';
  list-style: 'none';
  padding: 0px;
}

@media only screen and (min-width: 1400px) {
  .width_about_70 {
    width: 900px !important;
  }

  .width_about_image {
    width: 650px !important;
  }
}

@media only screen and (max-width: 1000px) {
  .width_about_60 {
    /* width: 50%; */
  }

  .width_about_image {
    width: 100% !important;
  }
}
