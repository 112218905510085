.generate-link-page {
  width: 100%;
  height: calc(100vh - 30vh);
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-top: 100px;
}

.generate-link-page-container {
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 40%;
  max-width: 600px;
  gap: 30px;
}

.generate-link-page-container-inputs-div {
  justify-content: space-around;
  align-items: center;
  gap: 30px;
}

.generate-link-page-container-inputs-div-text-input {
  width: 100%;
  margin: 0px !important;
}

.generate-link-page-container-inputs-div-buttons {
  gap: 10px;
  display: flex;
  width: 100%;
}

.generate-link-page-container-inputs-div-string-generate {
  background-color: #f05436;
  border-radius: 10px;
  width: 50%;
  font-size: 17px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  font-weight: 700;
  border: 1px solid #f05436;
}

.generate-link-page-container-inputs-div-string-generate:hover {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  font-weight: 800;
  background-color: #09091d;
  color: #f05436;
}

.Error_msg_text {
  color: rgb(212, 51, 51);
  font-weight: 100;
  font-size: smaller;
}

.generate-link-page-container-cretaed-link {
  font-size: 17px;
  color: white;
  text-align: center;
  margin-top: 20px;
  gap: 10px;
}

.clipbpard-icon {
  cursor: pointer;
}

.tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
