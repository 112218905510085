/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
} */

@font-face {
  font-family: 'poppinsblack';
  src:
    url('../src/assets/Poppins/poppins-black-webfont.woff2') format('woff2'),
    url('../src/assets/Poppins/poppins-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppinsbold';
  src:
    url('../src/assets/Poppins/poppins-bold-webfont.woff2') format('woff2'),
    url('../src/assets/Poppins/poppins-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppinslight';
  src:
    url('../src/assets/Poppins/poppins-light-webfont.woff2') format('woff2'),
    url('../src/assets/Poppins/poppins-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppinsmedium';
  src:
    url('../src/assets/Poppins/poppins-medium-webfont.woff2') format('woff2'),
    url('../src/assets/Poppins/poppins-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppinssemibold';
  src:
    url('../src/assets/Poppins/poppins-semibold-webfont.woff2') format('woff2'),
    url('../src/assets/Poppins/poppins-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppinslight';
  src:
    url('../src/assets/Poppins/poppins-light-webfont.woff2') format('woff2'),
    url('../src/assets/Poppins/poppins-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppinsthin';
  src:
    url('../src/assets/Poppins/poppins-thin-webfont.woff2') format('woff2'),
    url('../src/assets/Poppins/poppins-thin-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppinsregular';
  src:
    url('../src/assets/Poppins/poppins-regular-webfont.woff2') format('woff2'),
    url('../src/assets/Poppins/poppins-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Poppins', sans-serif !important;
}
