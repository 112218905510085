@import 'bootstrap/dist/css/bootstrap.min.css';

.width_89 {
  width: 88.5%;
  padding-bottom: 70px;
}

.md_section_founder_story {
  padding-bottom: 0px !important;
}

@media only screen and (min-width: 1400px) {
  .width_89 {
    width: 12750px !important;
  }
}
