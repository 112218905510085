@import 'bootstrap/dist/css/bootstrap.min.css';

.toggleBtnDiv {
  align-items: right;
  justify-content: start !important;
}

.toggleBtn {
  background-color: #f05436;
  border-radius: 8px;
  border: none;
  display: none;
}

.toggle-logout-btn {
  border: 0px;
  background: none;
  color: #f05436;
  align-items: start;
  font-size: 15px;
  font-weight: 600;
  text-align: left;
  padding: 0px;
}

.toggleOptions {
  background-color: white;
  padding: 5px;
  border-radius: 8px;
  margin-top: 4px;
  position: absolute;
  right: 1rem;
}

.selectOptions {
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .toggleBtn {
    display: block;
  }
}

@media only screen and (min-width: 767px) {
  .toggleOptions {
    display: none;
  }
}
