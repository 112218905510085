@import 'bootstrap/dist/css/bootstrap.min.css';

.current_workplace_background {
  background: #09091d;
  height: 100vh !important;
  padding-top: 2rem;
}

.current_workplace_logoCircleDiv {
  align-items: center;
  display: flex;
  margin-bottom: 20px;
  margin-left: 2rem;
  -webkit-box-shadow: -2px 0px 31px -10px rgba(255, 255, 255, 1);
  -moz-box-shadow: -2px 0px 31px -10px rgba(255, 255, 255, 1);
  box-shadow: -2px 0px 31px -10px rgba(255, 255, 255, 1);
}

.current_workplace_circletitleLogo {
  font-family: 'Poppins', sans-serif;
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f05436;
  background-color: #fff;
  border-radius: 50%;
  font-weight: 600;
  height: 75px;
  width: 75px;
  box-shadow: 0px 0px 28px white;
  text-align: center;
  border: none;
  margin-left: 2rem;
}

.current_workplace_logoCircleDiv {
  border-radius: 50%;
  background-color: white;
}
