@import 'bootstrap/dist/css/bootstrap.min.css';

.spinner-div {
  justify-content: center;
  align-items: center;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;
}

.spiner {
  font-size: 30px;
  height: 50px;
  width: 50px;
  color: #f05436;
}

.cardDiv {
  align-items: center;
  justify-content: center;
  align-items: center;
  margin: auto;
  /* display: grid; */
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(10px, 12rem);
  gap: 40px;
  margin-top: 30px;
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
}

.card-class {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  font-family: 'poppins';
  position: relative;
  background-color: #09091d;
  box-shadow: 0px 0px 7px white;
  border-radius: 16px;
  padding: 27px 18px;
  max-width: 260px;
  width: 260px;
  height: 180px;
}

.cardTitle {
  color: #f05436;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1.5px;
  margin-top: 20px;
  text-overflow: ellipsis;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.plusIconDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 10px 15px 10px;
}

.plusIcon {
  border-radius: 50%;
  padding: 17px;
  box-shadow: 0px 0px 18px #f05436;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f05436;
  text-align: center;
  cursor: pointer;
}

.dataCard {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: 'poppins';
  position: relative;
  background-color: #09091d;
  box-shadow: 0px 0px 7px white;
  border-radius: 16px;
  padding: 25px 20px 10px 20px;
  height: 180px;
  width: 260px;
  max-width: 260px;
}

.child-card-div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.data-card-heading {
  color: white;
  letter-spacing: 2px;
  font-size: 9px;
  margin-bottom: 1px;
}

.data-card-title {
  color: #f05436;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1.5px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  margin-top: 3px;
}

.data-card-img-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* margin-top: 40px; */
}

.data-card-img-div-without-marging {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
}

.data-card-img {
  height: 33px;
  width: 31px;
  margin-right: 5px;
}

.data-card-span {
  color: white;
  font-size: 10px;
  display: flex;
  align-items: center;
}

.lottie-btns {
  background: none;
  border: none;
}

.data-card-buttons-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.delete-button-div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.deleteIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.open-btn {
  color: white;
  background-color: #f05436;
  border-radius: 10px;
  outline: none;
  border: none;
  font-size: 10px;
  padding: 0.5rem 1.6rem;
  text-decoration: none;
}

.delete_button_background {
  color: white;
  font-size: 10px;
  background-color: #09091d;
  border: none;
}

@media only screen and (max-width: 1190px) {
  .cardDiv {
    grid-template-columns: repeat(4, 200px);
  }
}

@media only screen and (max-width: 995px) {
  .cardDiv {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 750px) {
  .cardDiv {
    grid-template-columns: repeat(2, 1fr);
  }

  .card-class {
    max-width: 180px;
  }

  .dataCard {
    /* padding: 25px 20px 25px 20px; */
    max-width: 190px;
    height: 210px;
  }

  .data-card-img-div {
    /* background-color: red; */
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 545px) {
  .cardDiv {
    display: block;
    width: 100%;
  }

  .card-class {
    margin-bottom: 20px;
    min-width: 100%;
  }

  .dataCard {
    margin-bottom: 20px;
    min-width: 100%;
  }
}

@media only screen and (max-width: 1400px) {
  .newworkplace_container {
    width: 100% !important;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
}

@media only screen and (min-width: 1600px) {
  .newworkplace_container {
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .card-class {
    width: 190px;
  }

  .cardDiv {
    gap: 20px !important;
  }

  .dataCard {
    padding: 10px 20px 10px 20px;
    width: 100%;
    max-width: 300px;
  }
}

.dashboard_workspace-card-wrapper {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr) !important;
  gap: 40px 80px !important;
}

.dashboard_workspace-card {
  width: 100% !important;
  max-width: 100% !important;
}

.dashboard_workspace-card .card-class {
  width: 100% !important;
  max-width: 100% !important;
}

.dashboard_workspace-card.card-disabled .child-card-div {
  opacity: 0.2;
  position: relative;
}

.dashboard_workspace-card.card-disabled .disabled-lable {
  padding: 0px 15px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
}

.dashboard_workspace-card.card-disabled .lottie-btns:disabled div {
  cursor: default !important;
}

@media only screen and (max-width: 1200px) {
  .dashboard_workspace-card-wrapper {
    gap: 40px 40px !important;
  }
}

@media only screen and (max-width: 991px) {
  .dashboard_workspace-card-wrapper {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media only screen and (max-width: 750px) {
  .dashboard_workspace-card-wrapper {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
