.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.diagram-component {
  top: 1vh;
  /* left: 0vw; */
  width: 100vw;
  height: calc(100vh + 60px);
  max-height: calc(100vh + 32px);
  /* border: solid 1px black; */
  /* background-color: white; */
  overflow: hidden;
}

canvas {
  /* overflow: hidden;
  --svg-bg: #09091d;
  --svg-size: 48px;
  --svg-space: 18px;

  background:
    linear-gradient(90deg, var(--svg-bg) calc(var(--svg-space) / 2 - var(--svg-size) / 2), transparent 1%) center /
      calc(var(--svg-space) + var(--svg-size)) calc(var(--svg-space) + var(--svg-size)),
    linear-gradient(var(--svg-bg) calc(var(--svg-space) / 2 - var(--svg-size) / 2), transparent 1%) center /
      calc(var(--svg-space) + var(--svg-size)) calc(var(--svg-space) + var(--svg-size)),
    url('/public/assets/images/pattern.svg') center / var(--svg-size) var(--svg-size); */
}

.menu {
  display: none;
  position: absolute;
  opacity: 0;
  margin: 0;
  padding: 8px 0;
  z-index: 999;
  box-shadow:
    0 5px 5px -3px rgba(255, 255, 255, 0.2),
    0 8px 10px 1px rgba(255, 255, 255, 0.14),
    0 3px 14px 2px rgba(255, 255, 255, 0.12);
  list-style: none;
  background-color: #ffffff;
  border-radius: 4px;
}

.menu-item {
  display: block;
  position: relative;
  min-width: 60px;
  margin: 0;
  padding: 6px 16px;
  /* font: bold 12px sans-serif; */
  font-weight: 800;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
}

.show-menu,
.menu-item:hover > .menu {
  display: block;
  opacity: 1;
}

#root {
  background-image: linear-gradient(rgba(240, 84, 54, 0.1) 0.5px, transparent 0.75px),
    linear-gradient(90deg, rgba(240, 84, 54, 0.1) 0.5px, transparent 0.75px),
    linear-gradient(rgba(240, 84, 54, 0.02) 0.75px, transparent 0.75px),
    linear-gradient(90deg, rgba(240, 84, 54, 0.02) 0.75px, #09091d 0.75px);
  background-size:
    100px 100px,
    100px 100px,
    25px 25px,
    25px 25px;
  background-position:
    -2px -2px,
    -2px -2px,
    -1px -1px,
    -1px -1px;
  min-height: calc(100vh);
}
