@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,600;1,300;1,500;1,600&display=swap');

.register_background {
  /* background: #09091d; */
}

.register_height100vh {
  height: 100vh;
}

.register_logo_div {
  padding-left: 39px;
  padding-top: 17.5px;
}

.register_form_manager {
  justify-content: center !important;
  align-items: center !important;
  margin: auto !important;
  height: 90vh;
  display: flex;
}

.register_formik_div {
  width: 40%;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.register_lightRed {
  color: #f05436;
}

.register_width_container {
  width: 60% !important;
}

.width_register_60 {
  width: 60%;
  font-weight: 500;
}

@media only screen and (min-width: 1500px) {
  .register_logo_div {
    /* width: 80%; */
    width: 1500px;
    justify-content: center;
    margin: auto;
  }
}

@media only screen and (min-width: 1399px) {
  .register_formik_div {
    width: 480px;
  }
}

@media only screen and (max-width: 1099px) {
  .register_formik_div {
    width: 420px;
  }
}

@media only screen and (max-width: 700px) {
  .register_formik_div {
    width: 350px;
  }

  .width_register_60 {
    width: 80%;
  }
}

@media only screen and (max-width: 450px) {
  .register_formik_div {
    width: 90%;
    padding: 0 5px !important;
  }
  .register_width_container {
    width: 90% !important;
  }
  .registerBtn {
    display: none;
  }
  .registerBtnMobile {
    display: inline-block !important;
  }
}
@media only screen and (min-width: 450px) {
  .registerBtnMobile {
    display: none !important;
  }
}
