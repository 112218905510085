@import 'bootstrap/dist/css/bootstrap.min.css';
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,600;1,300;1,500;1,600&display=swap'); */

.forgot_logo_div {
  padding-left: 39px;
  padding-top: 17.5px;
}

.forgot_background {
  /* background: #09091d; */
}

.forgont_width {
  width: 40% !important;
}

.forgot_font_family {
  font-family: 'Poppins', sans-serif;
}

.forgot_lightRed {
  color: #f05436;
}

.forgot_font_size_48 {
  font-size: 48px;
}

.forgot_margin_top {
  margin-top: 45px;
}

.forgot_page_height {
  min-height: 100vh;
}

.forgot_formik_div {
  width: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.fontSize {
  font-size: 25px;
}

@media only screen and (min-width: 1500px) {
  .forgot_logo_div {
    /* width: 80%; */
    width: 1500px;
    justify-content: center;
    margin: auto;
  }
}

@media only screen and (min-width: 1199px) {
  .forgot_formik_div {
    max-width: 450px;
  }
}

@media only screen and (max-width: 768px) {
  .forgont_width {
    width: 60% !important;
  }

  .forgot_font_size_48 {
    font-size: 35px !important;
  }
}

@media only screen and (max-width: 600px) {
  .forgont_width {
    width: 80% !important;
  }
}

@media only screen and (max-width: 992px) {
  .forgot_font_size_48 {
    font-size: 40px !important;
  }
}
