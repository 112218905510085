@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Poppins:ital,wght@0,600;0,700;1,600&display=swap');
@import 'bootstrap/dist/css/bootstrap.min.css';

.submitBorderButton {
  border-radius: 10px;
  font-weight: 800;
  color: #f05436;
  background: #09091d;
  border: 1px solid #f05436;
  padding: 10px 15px;
  font-size: 18px;
  font-weight: 600;
  display: block;
  margin-top: 15px;
}

.submitBorderButton:hover {
  background-color: #f05436;
  color: white;
  padding: 10px 15px;
}
