@import 'bootstrap/dist/css/bootstrap.min.css';
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Poppins:ital,wght@0,600;0,700;1,600&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Poppins:ital,wght@0,600;0,700;1,600&display=swap'); */

.workspace-modal-body {
  background: #09091d;
  box-shadow: 0px 0px 12px 0px white;
  border-radius: 7px;
  padding: 12px 30px 0px 30px !important;
  height: calc(90vh - 30px);
  overflow: auto;
}

/* width */
.workspace-modal-body::-webkit-scrollbar {
  width: 4px;
}

.copyLinkIcon {
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0px 0px 18px #f05436;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f05436;
  text-align: center;
  cursor: pointer;
}

/* Track */
.workspace-modal-body::-webkit-scrollbar-track {
  border-radius: 20px !important;
  background: #f1f1f1;
}

/* Handle */
.workspace-modal-body::-webkit-scrollbar-thumb {
  border-radius: 20px !important;
  background: #f05436;
}

/* Handle on hover */
.workspace-modal-body::-webkit-scrollbar-thumb:hover {
  border-radius: 20px !important;
  background: #555;
}

.swal2-title {
  color: white;
}

.workspace-modal-nodetails {
  font-size: 17px;
  color: #f05436;
  font-family: 'Inter', sans-serif;
  line-height: 26px;
  margin-top: 8px;
}

.swal2-icon,
.swal2-warning,
.swal2-icon-show {
  color: #f05436 !important;
  border: 2px solid #f05436 !important;
}

.workspace-modal-title {
  color: #fff;
  margin: auto;
  font-size: 18px;
  color: white;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 2px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 0px;
}

.workspace-modal-input-btn-div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.workspace-modal-form-input-div {
  width: 69%;
}

.extraclass {
  padding: 8px;
  border-radius: 5px;
}

.workspace-modal-button {
  background-color: #f05436;
  border-radius: 5px;
  width: 20%;
  font-size: 16px;
  /* padding-top: 5px;
  padding-bottom: 5px; */
  height: 38px;
  color: white;
  border: 0;
  font-weight: 900;
  margin: auto;
  justify-content: center;
  display: block;
}

.workspace-modal-h4 {
  font-size: 17px;
  color: white;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
  line-height: 26px;
  margin-top: 8px;
}

.workspace-modal-gmail-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 20px;
  color: black;
  padding: 0.475rem 0.75rem;
  font-size: 14px;
  border: 0;
  width: 100%;
  box-shadow: rgb(240, 84, 54) 0px 0px 7px 0px;
}

.workspace-modal-gmail-box-h6 {
  color: white;
  text-align: left;
  align-items: center;
  font-size: 15px;
  font-weight: 700;
  margin-top: 5px;
  width: 65%;
  line-height: 20px;
  overflow-wrap: break-word;
}

.workspace-modal-gmail-box-button {
  border: none;
  background: none;
  color: white;
  letter-spacing: 3px;
  font-weight: 600;
  text-align: left;
  margin-left: 10px;
}

.workspace-modal-gmail-box-select-box {
  width: 35%;
  display: flex;
}

.workspace-modal-gmail-box-select-box .form-control option {
  background-color: white !important;
  width: 100% !important;
  box-shadow: 0 0 0 0 #00000000 !important;
  border-color: #00000000 !important;
  border-bottom: 1px solid #efefef !important;
}

.form-select:focus {
  border-color: none !important;
  border: none !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.drop-downs-flex {
  display: flex;
  justify-content: space-between;
}

.copy-button-tooltip {
  color: rgb(219, 11, 11);
  background-color: white;
}

.workspace-modal-General-box-select-box {
  /* width: 30%; */
}

.restrict-select-box {
  width: 40%;
  border: none !important;
  background-color: none !important;
  margin-bottom: 10px;
}

.workspace-modal-General-msg {
  font-size: 12px;
  font-weight: 500;
  color: white;
  margin-top: 5px;
}

.modal-custome-select-box {
  padding: 7px;
  border-radius: 4px;
  box-shadow: rgb(240, 84, 54) 0px 0px 7px 0px;
  background-color: #09091d;
  color: #f05436;
  outline: none;
  font-weight: 600;
}

.modal-custome-select-option-box {
  background-color: white !important;
  padding: 20px;
}

.no-data-div {
  text-align: center;
  padding: 50px;
}

.modal-spinner-div {
  text-align: center;
  padding: 20px;
}

.modal-selectbox-spinner-div {
  text-align: center;
  width: 60%;
}

@media only screen and (max-width: 555px) {
  .workspace-modal-gmail-box {
    padding: 0.475rem 10px;
    justify-content: start;
  }

  .workspace-modal-gmail-box-h6 {
    width: 60%;
    font-size: 12px;
    margin-right: 15px;
  }
}

@media only screen and (max-width: 433px) {
  .workspace-modal-gmail-box-h6 {
    width: 55%;
  }
}

@media only screen and (max-width: 380px) {
  .workspace-modal-gmail-box {
    justify-content: start;
  }

  .workspace-modal-gmail-box-h6 {
    font-size: 10px;
    width: 45%;
    margin-right: 10px;
    margin-top: 0px;
  }

  .workspace-modal-gmail-box-select-box {
    width: 20%;
    font-size: 10px;
  }
}

.swal2-popup {
  background: #09091d;
  box-shadow: 0px 0px 7px white;
  border-radius: 16px;
}

.confirm-button-class {
  color: white !important;
  border: none !important;
}

.title-class {
  font-size: 15px !important;
  color: white !important;
  font-weight: 800;
}

.icon-class {
  font-size: 10px !important;
  color: #f05436 !important;
  background: #09091d;
  border-color: #f05436 !important;
}

.swal2-html-container {
  color: #f05436 !important;
  font-size: 15px;
  font-weight: 800 !important;
}

.confirm-button-class .swal2-icon svg {
  width: 12px !important;
  height: 12px !important;
  background-color: red !important;
}

.swal2-actions .swal2-confirm {
  background: #09091d !important;
  color: white !important;
  border: none !important;
  font-weight: 800;
  box-shadow: none !important;
  border: 1px solid #f05436 !important;
}

.swal2-cancel {
  background-color: #f05436 !important;
  border: 1px solid #f05436 !important;
  color: #efefef !important;
  font-weight: 800;
}

.swal2-cancel:disabled {
  background-color: #8b8b8bc9 !important;
  border-color: none !important;
  border: none !important;
}

.swal2-cancel :hover {
  color: white !important;
}

.swal2-confirm:hover {
  color: #f05436 !important;
}

.swal2-loader {
  border: 4px dotted #f05436 !important;
}
