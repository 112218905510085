@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Poppins:ital,wght@0,600;1,300;1,500;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Poppins:ital,wght@0,600;1,300;1,500;1,600;1,700&display=swap');

.white_threesteps_shadow_warper {
  background-color: white;
  padding: 20px 40px;
  border-radius: 12px;
  margin-bottom: 15px;
  position: relative;
}

.margin_threesteps_5 {
  margin-top: 7rem !important;
  margin-bottom: 7rem !important;
}

.width_threesteps_80 {
  width: 78%;
}

.reverce_threesteps_Margin {
  margin-top: -10rem;
}

.specail_threesteps_section {
  font-size: 32px !important;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
  color: #09091d;
}
.width_threesteps_content_container {
  width: 50%;
}
.width_threesteps_90 {
  width: 80% !important;
}

.baner_threesteps_btn {
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 18px;
  background-color: #f05436;
  border: none;
  color: white;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
  border: 1px solid #f05436;
}
.baner_threesteps_btn:hover {
  background-color: #09091d;
  color: #f05436;
}

.width_threesteps_60 {
  width: 50%;
  padding: 1rem 3rem 1rem 4rem !important;
}

.border_threesteps_bottom {
  border-bottom: 0.5px solid rgb(165, 165, 165);
}
.white_threeSteps_shadow_warper:after {
  content: '';
  width: 96%;
  height: 15px;
  background: #ececec;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -15px;
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
}

.specail_threesteps_section {
  font-size: 32px !important;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
  color: #09091d;
}

@media screen and (max-width: 1000px) {
  .center_threesteps_responsive {
    justify-content: center;
    margin: auto;
    width: 60%;
  }

  .responsive_threesteps_content {
    width: 100% !important;
  }
}

@media only screen and (max-width: 992px) {
  .Manage_threesteps_Width {
    width: 80% !important;
  }
}

@media only screen and (max-width: 600px) {
  .white_threesteps_shadow_warper {
    padding: 10px 20px !important;
  }

  .margin_threesteps_5 {
    margin-top: 0px !important;
  }
}

@media only screen and (max-width: 450px) {
  .baner_threesteps_btn {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 15px;
  }
}
@media only screen and (min-width: 1600px) {
  .width_threesteps_60 {
    width: 50% !important;
  }
}
@media only screen and (max-width: 769px) {
  .width_threesteps_90 {
    width: 100% !important;
  }
  .center_threesteps_responsive {
    width: 100% !important;
  }
}
@media only screen and (min-width: 1600px) {
  .margin_threesteps_5 {
    margin-top: 10rem !important;
  }
  .width_threesteps_90 {
    width: 75% !important;
  }
}
