@import 'bootstrap/dist/css/bootstrap.min.css';

.login_fontSize {
  font-size: 48px !important;
}

.form_heading_fontfamily {
  font-family: 'Poppins', sans-serif;
}

.form_heading_lightRed {
  color: #f05436;
}

@media only screen and (max-width: 768px) {
  .login_fontSize {
    font-size: 35px !important;
  }
}
