@import 'bootstrap/dist/css/bootstrap.min.css';

.width_89 {
  width: 88.5%;
  padding-bottom: 70px;
}

.new_home .container {
  max-width: 1300px;
}

@media only screen and (min-width: 1400px) {
  .width_89 {
    width: 12750px !important;
  }
}

@media (max-width:992px) {
  .new_home .container {
    padding: 0 33px !important;
  }
}