@import 'bootstrap/dist/css/bootstrap.min.css';
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Poppins:ital,wght@0,600;0,700;1,600&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Poppins:ital,wght@0,600;0,700;1,600&display=swap'); */

.modal-body {
  background: #09091d;
  box-shadow: 0px 0px 12px 0px white;
  border-radius: 7px;
  padding: 40px 30px 30px 30px !important;
}

.modalTitle {
  color: #fff;
  margin: auto;
  font-size: 14px;
  color: white;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 2px;
  font-weight: 500;
}

.modalTitleTwo {
  font-size: 22px;
  color: #f05436;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
  line-height: 26px;
  margin-top: 8px;
}

.input-btn-div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.form-input-div {
  width: 69%;
}

.extraclass {
  padding: 8px;
  border-radius: 5px;
}

.button {
  background-color: #f05436;
  border-radius: 5px;
  width: 20%;
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: white;
  border: 0;
  font-weight: 900;
  margin: auto;
  border: 1px solid #f05436;
  justify-content: center;
  display: block;
}

.button:hover {
  background-color: #09091d;
  color: #f05436;
}

.modal-submit-msg {
  font-size: 16px;
  color: #f05436;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
}
