.mobile_restriction {
  display: none;
}

@media only screen and (max-width: 962px) {
  .mobile_restriction {
    display: block;
    position: fixed;
    width: 100vw;
    height: 100vh;
    margin: 0 auto;
    top: 0;
    left: 0;
    background: #09091d;
    z-index: 1000;
  }
  .mobile_restriction_box {
    box-shadow: 0px 0px 7px white;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100vw - 40px);
    padding: 20px;
    border-radius: 20px;
  }
  .mobile_restriction_box h3 {
    color: #f05436;
    /* font-size: 18px; */
    font-weight: 400;
    text-align: center;
  }
  .mobile_restriction_box .exclamation-icon {
    color: #f05436;
    margin: 0 auto;
    display: block;
    margin-bottom: 20px;
  }
  .mobile_restriction_box p {
    color: #fff;
    font-size: 12px;
    text-align: center;
    font-weight: 400;
    margin-bottom: 50px;
    margin-top: 20px;
  }
  .mobile_restriction_box .open-btn {
    margin: 0 auto;
    display: block;
    width: 100%;
    font-size: 14px;
    text-align: center;
  }
}
