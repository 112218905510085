@import 'bootstrap/dist/css/bootstrap.min.css';
/* 
body {
  background-color: #09091d !important;
} */

.dashboard_page-wrapper {
  min-height: 100vh;
}

.dashboard_page-container {
  width: 95%;
  display: grid;
  gap: 80px;
  padding: 30px;
  margin-top: 22px !important;
}

.dropdown_margin {
  /* margin-right: 5rem !important; */
  display: flex;
  gap: 20px;
  justify-content: flex-end;
}

@media only screen and (min-width: 1600px) {
  .dashboard_page-container {
    width: 75%;
    margin-top: 23px !important;
  }
}
