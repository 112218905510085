.tnb_containers {
  padding: 10px 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  min-height: 65px;
}

.tnb_menu-wrapper {
  display: flex;
  gap: 40px;
}

.tnb_logo-wrapper {
  margin: auto;
  margin-left: 20px;
}

.tnb_logo-wrapper a {
  color: #f05436;
  font-size: 25px;
  font-family: 'Poppins', sans-serif;
  margin-left: 5px;
  text-decoration: none;
}

.tnb_logo-wrapper span {
  color: white;
  text-shadow:
    0px 0px 5px #f05436,
    0 0 1em #f05436,
    0 0 0.1em #f05436;
}

.tnb_trial-alert-wrapper {
  margin: auto;
}

.tnb_trial-alert-wrapper a {
  color: #f05436;
  font-size: 12px;
  text-decoration: none;
}

.tnb_company-profile-dropdown-wrapper {
  padding-top: 0 !important;
}

.tnb_toggle-dropdown-wrapper {
  margin: auto;
}

.tnb_toggle-dropdown-wrapper .selectBoxDiv {
  padding: 0;
  width: fit-content;
}

.tnb_toggle-dropdown-wrapper .toggleBtnDiv {
  padding: 0;
}

.tnb_toggle-dropdown-wrapper .dropdownmanage {
  padding: 0;
  margin-right: 0 !important;
}

.tnb_processing-alert-wrapper {
  margin: auto;
}

.tnb_processing-alert-wrapper p {
  font-family: Poppins, sans-serif;
  font-size: 12px;
  color: #fff;
  margin: auto;
}

@media only screen and (max-width: 767px) {
  .tnb_company-profile-dropdown-wrapper {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .tnb_containers {
    padding-left: 0;
  }

  .tnb_trial-alert-wrapper {
    position: absolute;
    top: 2px;
    left: 20px;
  }
}
