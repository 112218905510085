@import 'bootstrap/dist/css/bootstrap.min.css';
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Poppins:ital,wght@0,600;1,300;1,500;1,600&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Poppins:ital,wght@0,600;1,300;1,500;1,600;1,700&display=swap'); */

.width_collaborative_60 {
  width: 60%;
  /* padding-top: 1rem; */
}

.margin_bottom {
  margin-bottom: 45px !important;
}

.tilte_collaborative_helper_small {
  color: white;
  font-size: 13px;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 2px;
  margin-top: 1rem !important;
  font-weight: 700;
}

.title_collaborative_text {
  color: #f05436;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
  font-size: 70px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
}

.width_collaborative_70 {
  width: 70%;
}

.btns_flex_div {
  display: flex;
}

.baner_collaborative_text {
  color: white;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
}

.trial_btn {
  border-radius: 15px;
  color: #f05436;
  border: 0.5px solid #f05436;
  box-shadow: 0 0 10px 3px #f05436;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1.33px;
  background: white;
  transition: 0.3s;
  margin-right: 20px;
  font-weight: 700;
  text-decoration: none;
  padding: 8px 18px;
}

.trial_btn:hover {
  background-color: #f05436;
  box-shadow: 0 0 10px 3px white;
  color: white;
}

.book_btn {
  border-radius: 15px;
  color: white;
  border: 0px;
  box-shadow: 0 0 10px 3px white;
  font-size: 20px;
  text-transform: uppercase;
  background: #f05436;
  transition: 0.3s;
  font-weight: 700;
  letter-spacing: 1.33px;
  padding: 8px 18px;
  text-decoration: none;
}

.book_btn:hover {
  background-color: white;
  box-shadow: 0 0 10px 3px #f05436;
  color: #f05436;
}

.baner_collaborative_btn {
  /* padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 10px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 18px;
  background-color: #f05436;
  border: none;
  color: white;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
  border: 1px solid #f05436; */
  border-radius: 15px;
  color: #f05436;
  border: 0.5px solid #f05436;
  box-shadow: 0 0 10px 3px #f05436;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1.33px;
  background: white;
  transition: 0.3s;
  margin-right: 20px;
  font-weight: 700;
  text-decoration: none;
  padding: 8px 18px;
}

.baner_collaborative_btn:hover {
  background-color: #f05436;
  box-shadow: 0 0 10px 3px white;
  color: white;
}

.dark_collaborative_btn {
  /* padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 10px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 18px;
  color: #f05436;
  border: 1px solid #f05436;
  font-size: 18px;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
  background: #09091d;
  transition: 0.3s;
  font-weight: 700; */
  border-radius: 15px;
  color: white;
  border: 0px;
  box-shadow: 0 0 10px 3px white;
  font-size: 20px;
  text-transform: uppercase;
  background: #f05436;
  transition: 0.3s;
  font-weight: 700;
  letter-spacing: 1.33px;
  padding: 8px 18px;
  text-decoration: none;
}

.dark_collaborative_btn:hover {
  background-color: white;
  box-shadow: 0 0 10px 3px #f05436;
  color: #f05436;
}

.width_collaborative_40 {
  width: 40%;
}

.marginTop_collaborative_6 {
  margin-top: 4rem;
}

/* 19-jan */
.home_page_revolution_title {
  color: white;
  text-shadow:
    0px 0px 5px #f05436,
    0 0 1em #f05436,
    0 0 0.1em #f05436;
  line-height: 84px;
  position: relative;
  font-size: 60px;
  width: 100%;
}

.md_title_text.revolution_title {
}

.md_title_text.revolution_title::after {
  content: 'AI-Powered visual thinking platform';
}

.md_sub_title_text.child_titles_text {
  font-size: 40px;
  line-height: 50px;
  /* font-family: 'poppinssemibold', sans-serif; */
}

.text_btns_div_p {
  font-size: 20px;
  font-family: 'poppinsregular', sans-serif;
}

.md_p_width {
  max-width: 340px;
  padding-bottom: 30px;
}

.md_p_width_tech {
  padding: 50px 0 30px;
  max-width: 100% !important;
}

.md_italilc_text {
  font-size: 15px;
  font-style: italic;
  line-height: 31px;
  font-weight: 400;
  display: block;
  padding: 10px 0 0 0;
  font-family: 'poppinsmedium', sans-serif;
}

.md_trust-text.child_titles_text {
  font-size: 20px;
  line-height: 28px;
  /* font-family: 'poppinsmedium', sans-serif; */
}

.md_text_btns_div {
  padding-bottom: 62px;
}

.md_text_btns_div_tech {
  padding-top: 40px;
  padding-bottom: 0px !important;
}

.md_hero_banner_img {
  box-shadow: 0 0 15px 3px #f05436;
  border-radius: 40px;
}

.md_banner_wrapper {
  align-items: end;
}

.md_vishual_wrapper {
  padding-top: 100px;
}

.main-img-resolution_tech {
  margin-bottom: auto;
}

/* 19-jan-end */

@media screen and (max-width: 991px) {
  .responsive_collaborative_content {
    width: 100%;
    justify-content: center;
    margin: auto;
  }

  .responsive_collaborative_image {
    margin-top: 2rem;
  }

  .responsive_collaborative_Button {
    margin-left: 0px !important;
    margin-top: 1rem;
  }

  .responsive_collaborative_heading {
    font-size: 55px;
  }

  .baner_collaborative_btn {
    width: 50%;
  }

  .dark_collaborative_btn {
    width: 50%;
  }

  .width_collaborative_40 {
    width: 75% !important;
  }
}

@media only screen and (max-width: 767px) {
  .md_vishual_wrapper {
    padding-top: 50px;
  }

  .md_hero_banner_img {
    border-radius: 30px;
  }

  .main-img-resolution {
    padding: 0px calc(var(--bs-gutter-x) * 0.6);
  }

  .responsive_Image_Collaborative {
    margin-top: 0px;
  }

  .marginTop_collaborative_6 {
    margin-top: 0px;
  }

  .width_collaborative_40 {
    width: 80% !important;
    justify-content: center;
    margin: auto;
  }

  .width_60 {
    width: 80% !important;
  }

  .Padding_Bottom_collabrative {
    padding-bottom: 0px !important;
  }

  .baner_collaborative_btn {
    width: 50%;
  }

  .dark_collaborative_btn {
    margin-left: 0rem !important;
    width: 50%;
  }

  .home_page_revolution_title {
    width: 90%;
    font-size: 33px;
    line-height: 45px;
  }

  .md_sub_title_text.child_titles_text {
    font-size: 25px;
    line-height: 30px;
  }

  .responsive_collaborative_content .text_btns_div_p {
    font-size: 15px !important;
    font-family: 'poppinsregular', sans-serif;
    padding-top: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .responsive_collaborative_heading {
    font-size: 50px;
  }

  .dark_collaborative_btn {
    margin-left: 0px !important;
    width: 50%;
  }

  .Button_collaborative_Manage {
    width: 100%;
    margin-left: 0px !important;
  }

  .dark_collaborative_btn {
    width: 50%;
  }

  .Padding_Bottom_collabrative {
    display: grid;
  }

  /* .md_italilc_text {
    padding: 7px 0 0 0;
  } */
}

@media only screen and (max-width: 450px) {
  .responsive_collaborative_heading {
    font-size: 40px;
  }

  .baner_collaborative_btn {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 15px;
  }

  .dark_collaborative_btn {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 15px;
  }
}

@media only screen and (min-width: 1600px) {
  .tilte_collaborative_helper_small {
    justify-content: center !important;
    align-items: center !important;
    margin: auto !important;
  }
}

@media only screen and (min-width: 1900px) {
  .width_collaborative_60 {
    padding-top: 3.5rem;
    padding-left: 0.5rem;
  }
}

@media only screen and (max-width: 650px) {
  .btns_flex_div {
    flex-wrap: wrap;
    gap: 30px;
  }

  .trial_btn {
    width: 100%;
    margin-right: 0px !important;
  }

  .book_btn {
    width: 100%;
    margin-right: 0px !important;
  }
}

@media only screen and (max-width: 380px) {
  .home_page_revolution_title {
    width: 100%;
  }
}
