@import 'bootstrap/dist/css/bootstrap.min.css';
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Poppins:ital,wght@0,600;1,300;1,500;1,600&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Poppins:ital,wght@0,600;1,300;1,500;1,600;1,700&display=swap'); */

.white_introducing_shadow_warper {
  background: white;
  padding: 1rem 0px;
  border-radius: 12px;
  margin-bottom: 15px;
  position: relative;
}

.steps_section_containe {
  width: 100%;
  margin: 150px 0;
  border-radius: 40px;
  min-height: unset;
  box-shadow: 0 0 30px white;
  background-color: #09091d;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 25px;
}

.steps_section_containe_one {
  width: 20;
  max-width: 150px;
}

.steps_section_containe_two {
  width: 20;
  max-width: 140px;
}

.steps_section_containe_three {
  width: 20;
  max-width: 235px;
}

.steps_section_containe_four {
  width: 20;
  max-width: 205px;
}

.steps_section_containe_five {
  width: 20;
  max-width: 235px;
}

.width_85 {
  width: 85%;
}

.width_introducing_main_container {
  width: 90% !important;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.width_introducing_60 {
  width: 60%;
}

.height_introducing_manager {
  justify-content: center !important;
  align-items: center !important;
  margin: auto;
}

.width_introducing_70 {
  width: 40%;
}

.Width_introducing_Management_Heading {
  width: 100% !important;
}

.sub_title_introducing_drak {
  font-size: 48px;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
  color: #09091d;
}

.baner_introducing_btn {
  padding-top: 7px;
  padding-bottom: 7px;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 18px;
  background-color: #f05436;
  border: none;
  color: white;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
  border: 1px solid #f05436;
}

.baner_introducing_btn:hover {
  background-color: #09091d;
  color: #f05436;
}

.white_introducing_shadow_warper:after {
  content: '';
  width: 96%;
  height: 15px;
  background: #ececec;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -15px;
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
}

/* 19-jan */
.md_no {
  display: flex;
  align-items: center;
  gap: 8px;
  /* width: 20%; */
}

.md_no .revolution_title {
  /* width: 20%; */
  margin-bottom: 0;
}

.md_no_one.revolution_title::after {
  content: '1';
}

/* .md_no .md_no_two.revolution_title {
  width: 25%;
} */

.md_no_two.revolution_title::after {
  content: '2';
}

.md_no_three.revolution_title::after {
  content: '3';
}

.md_no_four.revolution_title::after {
  content: '4';
}

.md_no_five.revolution_title::after {
  content: '5';
}

.md_number_wrapper {
  width: 100%;
  margin: 150px 0;
  border-radius: 40px;
  min-height: unset;
  box-shadow: 0 0 30px white;
  background-color: #09091d;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 25px;
  /* gap: 1px; */
}

.md_card_text.child_titles_text {
  font-size: 23px;
  line-height: 25px;
  margin-bottom: 0;
  /* font-family: 'poppinssemibold', sans-serif; */
}

.steps_section .revolution_title {
  width: auto;
}

/* end */

@media only screen and (min-width: 1400px) {
  /* .md_number_wrapper_parent .md_number_wrapper {
    gap: 10px;
  } */
}

/* @media only screen and (max-width: 1400px) {
  .md_no {
    gap: 1px;
  }
} */

@media only screen and (max-width: 991px) {
  .steps_section_containe {
    /* flex-wrap: wrap;
    justify-content: flex-start; */
    display: block;
  }

  .steps_section_containe_one {
    width: 20;
    max-width: 100%;
  }

  .steps_section_containe_two {
    width: 20;
    max-width: 100%;
  }

  .steps_section_containe_three {
    width: 20;
    max-width: 200%;
  }

  .steps_section_containe_four {
    width: 20;
    max-width: 200%;
  }

  .steps_section_containe_five {
    width: 20;
    max-width: 200%;
  }

  .md_number_wrapper {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .md_no {
    width: 100%;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .md_no .revolution_title {
    width: auto;
    margin-bottom: 0;
  }

  .md_no .md_no_two.revolution_title {
    width: auto;
  }
}

@media screen and (max-width: 992px) {
  .responsive_introducing_Image {
    width: 60%;
    justify-content: center;
    margin: auto;
  }

  .Responsive_introducing_Content {
    justify-content: center;
    width: 70%;
    margin-bottom: 3rem;
  }
}

@media only screen and (max-width: 767px) {
  .steps_section_containe {
    box-shadow: 0 0 17px white;
    margin: 70px auto 70px;
    justify-content: center;
    padding-top: 30px;
    border-radius: 30px !important;
  }

  .md_number_wrapper {
    box-shadow: 0 0 17px white;
    margin: 70px auto 70px;
    justify-content: center;
    padding-top: 30px;
    border-radius: 30px !important;
  }

  .md_getstartted_wrapper .md_number_wrapper {
    margin: 70px 0px;
    justify-content: flex-start;
    max-width: unset;
  }

  .md_card_text.child_titles_text {
    text-align: center;
    font-size: 20px;
  }
}

@media screen and (max-width: 550px) {
  .sub_title_introducing_drak {
    font-size: 30px;
  }

  .Width_introducing_Full {
    width: 100% !important;
  }
}

@media only screen and (max-width: 450px) {
  .baner_introducing_btn {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 15px;
  }

  /* .md_number_wrapper {
    max-width: 289px;
  } */
}

@media only screen and (min-width: 1600px) {
  .md_card_text.child_titles_text {
    /* width: 190px; */
  }

  .width_introducing_main_container {
    width: 94% !important;
  }

  .height_introducing_manager {
    padding-left: 2rem;
  }

  .baner_introducing_btn {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 13px;
    padding-right: 13px;
  }

  .white_introducing_shadow_warper {
    margin-top: 5rem !important;
  }
}
