@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Poppins:ital,wght@0,600;1,300;1,500;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Poppins:ital,wght@0,600;1,300;1,500;1,600;1,700&display=swap');

.md_section_wrapper_mobile {
  /* justify-content: space-around;
  width: 100%; */
}

.responsive_collaborative_video {
  border-radius: 40px;
  box-shadow: 0 0 15px 3px #f05436;
  height: auto;
}

.md_section_wrapper_info_section {
  flex: wrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.md_section_wrapper_info_section_reverse {
  flex: wrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  padding: 150px 0;
}

.md_section_wrapper_info_section_one {
  width: 45%;
}

.md_section_wrapper_info_section_reverse_one {
  width: 45%;
}

.md_section_wrapper_info_section_two {
  width: 45%;
}

.md_section_wrapper_info_section_reverse_two {
  width: 45%;
}

.padding_tailore_section {
  padding: 0px;
}

.image_tailore_manager_container {
  justify-content: flex-start;
  align-items: left;
  margin: auto;
  display: flex;
}

.image_tailore_manager {
  width: 60% !important;
}

.image_tailore_container {
  padding: 0px;
}

.contant_tailore_container {
  width: 46%;
  padding-right: 6rem !important;
  padding-top: 5rem;
}

.tilte_tailore_helper_small {
  color: white;
  font-size: 13px;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 2px;
  font-weight: 700;
}

.padding_tailore_bottom {
  padding-bottom: 5rem !important;
  padding-top: 2rem;
}

.org_tailore_title_drak {
  font-size: 32px;
  color: #f05436;
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
  width: 40%;
}

.details_tailore_h2 {
  width: 340px;
}

.details_tailore_paragraph {
  width: 100%;
  font-weight: 500;
}

.dark_tailore_btn {
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 18px;
  color: #f05436;
  border: 1px solid #f05436;
  font-size: 18px;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
  background: #09091d;
  transition: 0.3s;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 700;
}

.dark_tailore_btn:hover {
  background-color: #f05436;
  color: white;
}

/* 19-jan */
.md_business_text.revolution_title {
  font-size: 50px;
  line-height: 70px;
  margin-bottom: 0;
}

.md_business_text.revolution_title::after {
  content: 'Businesses value data, not colors';
}

.md_business_text.md_tailored_text.revolution_title::after {
  content: 'Tailored with your context in mind';
}

.md_business_text.md_know_text.revolution_title::after {
  content: 'Know your next step';
}

.md_business_text.md_easily_text.revolution_title::after {
  content: 'Easily shareable';
}

.md_business_text.md_science_text.revolution_title::after {
  content: 'Science behind it';
}

.md_p_details {
  padding: 50px 0 30px;
}

.md_row_reverse {
  flex-direction: row-reverse;
  padding: 150px 0;
}

.md_section_wrapper .md_hero_banner_img {
  /* max-width: 550px; */
  /* margin: 0 auto; */
  box-shadow: unset;
  border-radius: unset;
}

.md_section_wrapper .md_hero_banner_img img {
  box-shadow: 0 0 15px 3px #f05436;
  border-radius: 40px;
}

.md_get-title {
  font-size: 50px;
  line-height: 71px;
  color: #f05436;
  font-weight: 600;
}

.md_turn_text {
  padding: 8px 0 20px;
}

.md_text_btns {
  margin-bottom: 20px;
}

.md_list li {
  font-size: 20px;
  line-height: 28px;
  color: #f05436;
  padding-bottom: 10px;
  list-style-type: none;
}

.checked_svg {
  margin-right: 10px;
}

.md_align_text {
  padding-right: 20px;
}

/* end */

@media only screen and (max-width: 991px) {
  .md_section_wrapper {
    text-align: center;
  }

  .md_p_details {
    padding: 30px 0;
  }

  .md_list {
    padding-left: 0;
    padding-top: 20px;
  }

  .md_section_wrapper .revolution_title:after {
    right: 0;
  }

  .md_section_wrapper .md_hero_banner_img {
    margin: 0 auto;
  }

  .md_business_text.revolution_title {
    line-height: 60px;
  }

  .md_align_text {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
  }
}

@media screen and (max-width: 992px) {
  .contant_tailore_container {
    width: 70%;
    padding: 3rem;
  }

  .responsive_tailore_contant_container {
    justify-content: center;
    width: 100%;
  }

  .image_tailore_manager {
    width: 100% !important;
  }
}

@media only screen and (max-width: 767px) {
  .responsive_collaborative_video {
    border-radius: 30px;
  }

  .md_section_wrapper_info_section_one_text {
    font-size: 15px !important;
  }

  .md_list li {
    font-size: 15px;
    line-height: 20px;
  }

  .md_section_wrapper .md_hero_banner_img img,
  .md_section_wrapper .md_hero_banner_img video {
    border-radius: 30px;
  }

  .md_section_wrapper_mobile-view {
    margin-top: 70px;
  }

  .md_row_reverse {
    padding: 65px 0;
  }
}

@media only screen and (max-width: 768px) {
  .image_tailore_manager {
    width: 75% !important;
    justify-content: center !important;
    margin: auto !important;
  }

  .image_tailore_manager_container {
    justify-content: center !important;
    margin: auto !important;
  }

  .padding_tailore_bottom {
    padding-bottom: 0rem !important;
  }
}

@media screen and (max-width: 550px) {
  .org_tailore_title_drak {
    font-size: 30px;
  }

  .details_tailore_h2 {
    width: 70% !important;
  }

  .dark_tailore_btn {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 15px;
  }

  .md_business_text.revolution_title {
    width: 100%;
    font-size: 40px;
    line-height: 50px;
  }

  .md_get-title {
    font-size: 40px;
    line-height: 45px;
    text-align: center;
  }

  .md_getstartted_wrapper .text_btns_div_p {
    text-align: center;
  }

  .md_text_btns {
    text-align: center;
  }
}

@media only screen and (min-width: 1600px) {
  .image_tailore_manager_container {
    width: 55% !important;
  }

  .contant_tailore_container {
    width: 45%;
  }

  .details_tailore_paragraph {
    width: 90% !important;
  }

  .dark_tailore_btn {
    margin-top: 0.5rem !important;
  }
}

@media screen and (max-width: 995px) {
  .md_section_wrapper_info_section {
    display: block !important;
  }

  .md_section_wrapper_info_section_reverse {
    display: block !important;
  }

  .md_section_wrapper_info_section_one {
    width: 100%;
  }

  .md_section_wrapper_info_section_reverse_one {
    width: 100%;
  }

  .md_section_wrapper_info_section_two {
    width: 100%;
  }

  .md_section_wrapper_info_section_reverse_two {
    width: 100%;
  }

  .responsive_collaborative_video {
    height: auto !important;
  }
}

@media screen and (max-width: 1400px) {
  .responsive_collaborative_video {
    height: auto;
  }
}

@media screen and (max-width: 1150px) {
  .responsive_collaborative_video {
    height: auto;
  }
}
