.md_section_wrapper_client_section {
  margin-top: 150px;
}
.md_section_wrapper_client_section .md_business_text.md_know_text.revolution_title::after {
  content: 'What clients have to say';
}
.client_review_wrapper {
  display: flex;
  color: #fff;
}
.client_review_wrapper .client {
  flex: 1;
}
.md_section_wrapper_client_section .md_hero_banner_img img {
  border-radius: 40px;
}
.client_review_wrapper .client .client_top {
  display: flex;
  align-items: center;
  gap: 31px;
  margin-top: 52px;
}
.client_review_wrapper .md_section_content {
  max-width: 320px;
  font-size: 25px;
  font-weight: 500;
  font-family: 'poppinsregular', sans-serif;
}
.client_auth {
  margin-top: 35px;
  font-weight: 600;
  font-size: 20px;
  font-family: 'poppinsregular', sans-serif;
  color: #f05436;
}

@media (max-width: 995px) {
  .client_review_wrapper {
    display: block;
  }
  .md_section_wrapper_client_section {
    text-align: center;
  }
  .client_review_wrapper .client .client_top {
    flex-direction: column;
  }
  .client_review_wrapper .md_section_content {
    max-width: unset;
  }
  .client_auth {
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .md_section_wrapper_client_section .md_hero_banner_img img {
    border-radius: 30px;
  }
}
