.myaccount_page-wrapper {
  min-height: 100vh;
}

.myaccount_page-title-wrapper {
  width: 95%;
  margin: auto;
  padding: 0 30px;
  margin-top: 71.5px;
}

.myaccount_page-title {
  color: #f05436;
  font-size: 28px;
  font-weight: 800;
  font-style: normal;
}

.myaccount_page-container {
  width: 95%;
  display: grid;
  gap: 80px;
  padding: 30px;
}

.set-lineheight {
  line-height: 45px;
}
.sub_button {
  font-size: 14px;
  font-weight: 700;
  border-radius: 10px;
}
.rnd_input {
  border-radius: 10px;
  padding: 0.25rem 0.5rem !important;
  box-shadow: 0px 0px 15px 5px #f05436;
}

.rnd_input.filled {
  color: white;
  background-color: #09091d !important;
}

.mt-8 {
  margin-top: 5rem;
}

.myaccount_page-spinner-wrapper {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 12;
}

.myaccount_page-spiner {
  font-size: 30px;
  height: 50px;
  width: 50px;
  z-index: 2;
  cursor: pointer;
  color: #f05436;
}

@media only screen and (min-width: 1600px) {
  .myaccount_page-container {
    width: 75%;
  }

  .myaccount_page-title-wrapper {
    width: 75%;
  }
}
