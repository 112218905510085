@import 'bootstrap/dist/css/bootstrap.min.css';

.responsive_image_contanct {
  width: 50% !important;
  justify-content: start;
  align-items: center;
  margin-left: 10px;
}

.resposive_peta_image_width {
  width: 500px;
}

@media only screen and (min-width: 1400px) {
  .responsive_image_contanct {
    width: 50% !important;
    /* width: 480px; */
    /* background-color: white; */
  }
}

@media only screen and (max-width: 800px) {
  .responsive_image_contanct {
    width: 100% !important;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

@media only screen and (max-width: 600px) {
  .responsive_image_contanct {
    width: 100% !important;
  }

  .resposive_peta_image_width {
    width: 80% !important;
  }
}
