@import 'bootstrap/dist/css/bootstrap.min.css';

.toggleBtnLinksDiv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding-right: 20px;
}

.linkBtn {
  color: white;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  text-decoration: none;
}

@media only screen and (max-width: 767px) {
  .toggleBtnLinksDiv {
    display: none;
  }
}
