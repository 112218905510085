@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,600;1,300;1,500;1,600&display=swap');

body {
  background-color: #09091d;
}

.login_background {
  /* background: #09091d; */
}

.login_padding_bottom {
  /* padding-bottom: 2rem; */
  align-items: center;
  display: flex;
  margin: auto;
  justify-content: center;
  height: 90vh;
}

.login_page_height {
  height: 100vh;
  /* background-color: #09091d; */
  justify-content: center !important;
  align-items: center !important;
  margin: auto !important;
  position: relative;
}

.login_logo_div_manage {
  padding-left: 39px;
  padding-top: 17.5px;
}

.login_form_paragraph {
  color: #fff;
  width: 72%;
  padding: 10px 0px;
  justify-content: center;
  margin: auto;
  font-size: 16px;
  font-weight: 500;
}

.login_fields_div {
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 50%;
}

.login_lightRed {
  color: #f05436;
}

.login_width_45 {
  width: 60% !important;
}

.login_buttonbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  width: 100%;
  margin-top: 16px;
}

/* .width_70 {
  width: 70%;
  background-color: red;
} */
.registerBtn {
  /* width: 20%; */
  border-radius: 10px;
  font-weight: 800;
  color: #f05436;
  background: #09091d;
  border: 1px solid #f05436;
  padding: 10px 15px;
  font-size: 18px;
  font-weight: 600;
}

.registerBtn:hover {
  background-color: #f05436;
  color: white;
}

.forgotbtn {
  color: #f05436;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  text-decoration: none;
}

.forgotbtn:hover {
  text-decoration: underline;
}

.submitButtonwithGoogle {
  background-color: white;
  border-radius: 10px;
  width: 100%;
  font-size: 16px;
  height: fit-content;
  padding-top: 5px;
  padding-bottom: 5px;
  color: black;
  border: 0;
  font-weight: 700;
  margin-top: 1rem;
  border: 2px solid transparent;
}

.submitButtonwithGoogle:hover {
  border-top: 2px solid red;
  border-right: 2px solid yellow;
  border-bottom: 2px solid green;
  border-left: 2px solid rgb(30, 41, 249);
  background-color: #09091d;
  color: white;
}

.facebookBackgroundButton {
  width: 100%;
  background-color: rgb(76, 105, 186);
  margin-top: 1rem;
  border-radius: 0.5rem;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 15px;
  border: 2px solid transparent;
}

.facebookBackgroundButton:hover {
  border: 2px solid white;
}

.faceBookButton {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: rgb(76, 105, 186);
  border: none;
  color: white;
  font-weight: 600;
}

.login_facebook_loader {
  padding-top: 10px;
  background-color: rgb(76, 105, 186);
  border: none;
  color: white;
  font-weight: 600;
  padding: 15px 0px 0px 8px;
  margin-bottom: 10px;
  padding: 13px 0px 0px 8px;
  margin-bottom: 10px;
  font-size: 18px;
}

@media only screen and (min-width: 1500px) {
  .login_logo_div_manage {
    /* width: 80%; */
    width: 1500px;
    justify-content: center;
    margin: auto;
  }
}

@media only screen and (min-width: 1200px) {
  .login_fields_div {
    width: 480px;
  }
}

@media only screen and (max-width: 995px) {
  .login_fields_div {
    width: 380px;
  }
}

@media only screen and (max-width: 500px) {
  .buttonBox {
    display: grid;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .login_fields_div {
    width: 90%;
  }
}

@media only screen and (max-width: 500px) {
  .login_width_45 {
    width: 95%;
  }

  .login_fontSize_35 {
    font-size: 35px !important;
  }
}

@media only screen and (max-width: 700px) {
  .login_width_45 {
    width: 100% !important;
  }
}

@media only screen and (min-width: 1600px) {
  .login_width_45 {
    width: 40% !important;
  }
}
