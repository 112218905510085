@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Poppins:ital,wght@0,600;1,300;1,500;1,600;1,700&display=swap');

.success_section {
  justify-content: center;
  align-items: center;
  margin: auto;
  display: flex;
}

.success_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  margin-left: 100px;
  margin-right: 100px;
}

.success_img_container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.success_img_container_img {
  width: 500px;
}

.success_detail_container {
  width: 31%;
}

.success_detail_container_h1 {
  font-size: 48px;
  color: #f05436;
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
  width: 95% !important;
}

@media screen and (min-width: 1400px) {
  .success_section {
    justify-content: center;
    align-items: center;
    margin: auto;
    display: flex;
  }

  .success_container {
    width: 1250px !important;
    justify-content: space-around;
  }

  .success_img_container {
    width: 50%;
  }
}

@media screen and (max-width: 1200px) {
  .success_container {
    width: 100%;
    margin-top: 40px;
  }

  .success_img_container_img {
    width: 350px;
  }

  .success_detail_container_h1 {
    font-size: 38px;
  }
}

@media screen and (max-width: 900px) {
  .success_img_container_img {
    width: 300px;
  }
}

@media screen and (max-width: 750px) {
  .success_container {
    display: block;
    width: 100% !important;
  }

  .success_img_container {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100% !important;
  }

  .success_detail_container {
    width: 51%;
  }

  .success_detail_container {
    align-items: center;
    justify-content: center;
    margin: auto;
  }
}
