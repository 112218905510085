.maap-comment-card-container {
  display: flex;
  justify-content: space-between;
  background-color: #09091d;
}

.maap-comment-card {
  box-shadow: 0px 0px 8px white;
  padding: 12px 20px;
  border-radius: 15px;
  width: 100%;
  cursor: pointer;
}

.maap-comment-card-header {
  color: #f05436;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}

.maap-comment-card-header p {
  padding: 0;
  margin: 0;
}

.maap-comment-card-header svg {
  cursor: pointer;
}

.maap-comment-card-description {
  padding: 10px 0;
}

.maap-comment-card-description h5 {
  color: white;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 5px;
}

.maap-comment-card-description p {
  color: white;
  font-size: 12px;
  font-family: poppinsregular, Helvetica, Arial, sans-serif;
  font-weight: normal;
  margin-top: 2px;
  margin-bottom: 0px;
}

.maap-comment-card-description p a {
  color: white;
}
