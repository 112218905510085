@import 'bootstrap/dist/css/bootstrap.min.css';

/* body {
  overflow: hidden;
} */
body {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-y: auto;
  /* background: #09091d; */
  /* margin: 20px !important; */
  /* overflow: hidden; */
  /* --dot-bg: #09091d;
  --dot-color: #37474f;
  --dot-size: 2px;
  --dot-space: 32px;
  background:
    linear-gradient(90deg, var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center /
      var(--dot-space) var(--dot-space),
    linear-gradient(var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center / var(--dot-space)
      var(--dot-space),
    var(--dot-color); */
}

/* #root {
  --svg-bg: #09091d;
  --svg-size: 48px;
  --svg-space: 18px;

  background:
    linear-gradient(90deg, var(--svg-bg) calc(var(--svg-space) / 2 - var(--svg-size) / 2), transparent 1%) center /
      calc(var(--svg-space) + var(--svg-size)) calc(var(--svg-space) + var(--svg-size)),
    linear-gradient(var(--svg-bg) calc(var(--svg-space) / 2 - var(--svg-size) / 2), transparent 1%) center /
      calc(var(--svg-space) + var(--svg-size)) calc(var(--svg-space) + var(--svg-size)),
    url('/public/assets/images/pattern.svg') center / var(--svg-size) var(--svg-size);
  min-height: calc(100vh - 16px);
} */

.diagram-component .goTXarea {
  border-radius: 18px;
  padding: 0.5rem 0.8rem !important;
  font-size: 14px !important;
  box-shadow: 0px 0px 18px #f05436;
}
.diagram-component .goTXarea:focus-visible {
  outline: none;
  box-shadow: rgb(240, 84, 54) 0px 0px 7px 0px;
}
.mind-map-data-free-trial {
  margin: 0;
}
.mind-map-data-free-trial a {
  color: #f05436;
  font-size: 12px;
  position: absolute;
  top: 10px;
  left: 40px;
  text-decoration: none;
}
.mind-map-data-free-trial a:hover {
  color: #f25f42;
  text-decoration: none;
}
.mind-map-data-status {
  color: white;
  font-size: 12px;
  position: absolute;
  top: 10px;
  right: 250px;
}

/* CSS for the main menu */

.btn-container {
  bottom: 25px;
  right: 25px;
  position: absolute;
  z-index: 100;
  max-width: 100px;
}

.dropdown-menu.show {
  z-index: 10000;
}

.logoDiv_mindmap {
  width: 90%;
  justify-content: center;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 50px;
}

.mindmap_Main_Container {
  /* height: 100vh; */
  background-color: #09091d;
}

.btn-container1 {
  z-index: -1;
  bottom: 0;
  right: 0;
  position: absolute;
}

.menu1 {
  display: block;
  box-sizing: border-box;
  background: #fff;
  height: 40px;
  width: 45px;
  padding: 28px;
  border-radius: 50px;
  overflow: hidden;
  position: relative;
  border: 0px;
  transition: 1.3s cubic-bezier(0.53, 0, 0.15, 1.3);
}

.btn-container1 .menu1 {
  box-shadow: 0 0 7px 1.2px white;
}

.menu1.expanded {
  height: 378px;
  width: 45px !important;
  background-color: #f05436;
}

.menu1.viewer-expanded {
  height: 230px;
  width: 45px !important;
  background-color: #f05436;
}

.menu1 > span {
  width: 45px;
  display: block;
  padding: 8px 9px;
  white-space: nowrap;
  visibility: visible;
  opacity: 1;
  transition: 0.3s;
  transform: rotateY(0deg);
  color: #ff6e05;
  position: relative;
  cursor: pointer;
}

.menu1 > span:nth-of-type(1) {
  margin-top: -15px;
  transition-delay: 0.4s;
}

.menu1 > span:nth-of-type(2) {
  transition-delay: 0.5s;
}

.menu1 > span:nth-of-type(3) {
  transition-delay: 0.6s;
}

.menu1 > span:nth-of-type(4) {
  transition-delay: 0.7s;
}

.menu1 > span:nth-of-type(5) {
  transition-delay: 0.8s;
}

.menu1 > span:nth-of-type(6) {
  transition-delay: 0.9s;
}

.menu1 > span.hidden {
  width: 0;
  visibility: hidden;
  opacity: 0;
  transform: rotateY(90deg);
}

.menu1 > span.hidden:nth-of-type(1) {
  transition-delay: 0.5s;
}

.menu1 > span.hidden:nth-of-type(2) {
  transition-delay: 0.4s;
}

.menu1 > span.hidden:nth-of-type(3) {
  transition-delay: 0.3s;
}

.menu1 > span.hidden:nth-of-type(4) {
  transition-delay: 0.2s;
}

.menu1 > span.hidden:nth-of-type(5) {
  transition-delay: 0.1s;
}

.menu1 > span.hidden:nth-of-type(6) {
  transition-delay: 0s;
}

.menu1 > span {
  position: relative;
  width: 45px;
  color: #fff;
  text-decoration: none;
  font-size: 20px;
  transition: 0.3s;
  margin-left: -18.5px;
}

.menu1 > span:hover {
  color: black;
}

.toggle {
  position: relative;
  height: 40px;
  width: 45px;
  cursor: pointer;
  margin: 0;
  margin-top: 0px;
  margin-left: 0px;
  margin-top: 3px;
  margin-left: -12px;
}

.menu-icon {
  width: 57.33px;
  background-color: #fff;
  height: 57.33px;
  z-index: -1000000;
  margin-top: -28px;
  margin-left: -28px;
  border-radius: 30px;
}

.material-icons-round {
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}

.diagram-component > div {
  overflow: hidden !important;
}

.diagram-component canvas {
  margin-top: -70px;
}

.material-icons-round svg {
  height: 20px;
  width: 20px;
}

/* .container {
  position: absolute;
  top: 5px;
  right: calc(100% - 48px);
  height: 30.75px;
  width: 42px;
  transition: 1s;
} */

.mm_form-wrapper {
  margin-top: 110px;
}

.mindMamppage {
  background-color: #09091d;
  /* margin: 20px; */
  /* padding-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 100px; */
  /* height: 100vh; */
}

.myOverviewDiv {
  display: none;
}

.mindMamppage .selectBoxDiv {
  width: 100%;
}

.minmap_nav_dropdown {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
}

.minmap_nav_dropdown .selectBoxDiv {
  width: fit-content;
}

.overview-component-d-none {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  cursor: auto;
  position: absolute;
  width: 200px;
  height: 100px;
  top: 70px;
  right: 30px;
  background-color: #f2f2f2;
  z-index: 300;
  /* make sure its in front */
  border: solid 1px #f05436;
  animation: fadeOut 1s;
  opacity: 0;
  /* display: none; */
}

.overview-component-d-block {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  cursor: auto;
  position: absolute;
  width: 200px;
  height: 100px;
  top: 70px;
  right: 30px;
  background-color: #f2f2f2;
  z-index: 300;
  /* make sure its in front */
  border: solid 1px #f05436;
  animation: fadeIn 1s;
}

.mapIconContainer {
  height: 57px;
  width: 57px;
  z-index: 2000;
  cursor: pointer;
  position: absolute;
  top: 70px;
  right: 30px;
  border-radius: 50px;
  background-color: #fff;
  padding: 6px;
  box-shadow: 0 0 7px 1.2px white;
}

.mapIconContainer > span {
  color: #fff;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.mind-map-containers {
  /* height: calc(100vh - 123px); */
  overflow: hidden;
  /* display: flex !important; */
  /* justify-content: center !important; */
  background-color: #09091d;
  /* gap: 30px; */
  /* align-items: start !important; */
  height: calc(100vh - 65px);
}

.containers {
  display: flex !important;
  justify-content: center !important;
  background-color: #09091d;
  gap: 30px;
  align-items: flex-start !important;
}

.dropdpwn-div {
  display: flex;
  align-items: flex-start;
  margin: 0px 20px 0px 20px;
  /* padding: 20px 0px 10px 70px; */
  /* padding: 0px 0px 5px 35px; */
}

.logo-div {
  position: absolute;
  top: 4rem;
  left: 2rem;
  z-index: 99;
}

.container-icon-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 7px;
}

.loading-container-height-100vh {
  height: 100vh !important;
  background-color: #09091d;
}

@media only screen and (max-width: 767px) {
  .mind-map-containers {
    display: block;
    justify-content: center !important;
    align-items: center !important;
    margin: auto !important;
    margin-top: 20px;
  }

  .containers {
    display: block;
    justify-content: center !important;
    align-items: center !important;
    margin: auto !important;
  }
}

/* @media only screen and (min-width: 1600px) {
  .mind-map-containers {
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .logoDiv_mindmap {
    width: fit-content;
  }
} */

/* @media only screen and (min-width: 1400px) {
  .mind-map-containers {
    width: 1300px;
    justify-content: center !important;
    margin: 2rem auto !important;
    display: flex !important;
  }
} */
@media only screen and (min-width: 1400px) {
  .mind-map-containers {
    justify-content: center !important;
    /* width: 1300px; */
    display: flex;
    /* margin: auto; */
  }

  .containers {
    justify-content: center !important;
    width: 1300px;
    display: flex;
    margin: auto;
  }
}

.exportContainer {
  position: absolute;
  right: 25px;
  bottom: 25px;
  z-index: 1000;
  display: flex;
  gap: 20px;
}

.exportContainer .open-btn {
  background-color: #fff;
  color: #f05436;
  font-weight: bold;
  font-size: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 15px #fff;
}

.main-spinner {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.7);
}

.main-spinner img {
  opacity: 1;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: 80px;
}

.exportIdeasTitle {
  position: absolute;
  width: 80%;
  text-align: center;
  color: #fff;
  text-shadow: 2px 2px 10px #fff;
  font-size: 30px;
  height: 50px;
  z-index: 3;
  margin: 0 auto;
  padding-left: 20%;
  font-weight: 600;
}

.export-select-all-checkbox-wrapper {
  color: white;
  margin: auto;
  display: flex;
  gap: 10px;
  cursor: pointer;
}

.export-select-all-checkbox-wrapper .checked,
.export-select-all-checkbox-wrapper .unchecked {
  margin: auto;
}

.export-select-all-checkbox-wrapper span {
  cursor: pointer;
}

.mindmap_edit-page-workspace-title {
  position: absolute;
  top: 20px;
  left: 200px;
}

.mindmap_edit-page-workspace-title h1 {
  color: white;
  font-size: 14px;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  margin-top: 4px;
  margin-bottom: 0;
}

.mindmap_edit-page-workspace-title input {
  color: white;
  font-size: 14px;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  background: #09091d;
  border: unset;
  width: fit-content !important;
  min-width: 500px !important;
  padding: 0 !important;
  margin: 0 !important;
  outline: none !important;
}

.mindmap_edit-page-workspace-title input:focus-visible {
  outline: none !important;
}

@media only screen and (min-width: 1600px) {
  .mindmap_edit-page-workspace-title h1 {
    margin-top: 5px;
  }
}
