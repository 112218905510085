@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Poppins:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Poppins:wght@600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Poppins:ital,wght@0,600;0,700;1,600&display=swap');

.spinner-div-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  /* display: none; */
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 12;
}

.spiner-mind-map {
  font-size: 30px;
  height: 50px;
  width: 50px;
  z-index: 2;
  cursor: pointer;
  color: #f05436;
}

.spinner-div-overlay .spiner-message {
  text-align: center;
  color: white;
  margin-top: 50px;
  font-size: 18px;
}

.form_loader {
  color: white;
}

.main-container {
  width: 55%;
  position: absolute;
  left: 0;
  padding-left: 5%;
}

.form_first_title {
  color: #fefefe;
  font-size: 13px;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 2px;
  font-weight: 700;
}

.h1-tag {
  color: #f05436;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
  font-size: 48px;
  margin-top: 10px;
  margin-bottom: 30px;
}

.form-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80%;
}

.form-propmpt-input-div {
  width: 100%;
}

.form-button {
  background-color: #f05436;
  border: 1px solid #f05436;
  border-radius: 10px;
  width: 25%;
  font-size: 16px;
  /* padding-top: 10px;
  padding-bottom: 10px; */
  color: white;
  border: 0;
  font-weight: 900;
  margin: auto;
  justify-content: center;
  display: block;
  height: 45px;
}

.form-button:hover {
  background-color: #09091d;
  color: #f05436;
  border: 1px solid #f05436;
}

.experience-div {
  margin-top: 80px;
}

.form-h4 {
  color: #f05436;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
  font-size: 1.5rem;
}

.related-target {
  display: block;
}

.relatedInputContainer {
  margin-bottom: 20px;
}

.plusIconBorderButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 12px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #f05436;
  font-weight: 900;
  color: #f05436;
}

.addMoreBtn {
  background: none;
  border: none;
}

.plusIconBorderButton:hover {
  background-color: #f05436;
  color: #fff;
}

.textArea-Div {
  width: 58%;
}

.inputsDiv {
  width: 95%;
}

.border-radius-input {
  border-radius: 20px;
}

.fieldAraay {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(10px, auto);
  align-items: center;
  width: 90%;
  align-items: center;
}

.mind-map-fieldAraayError {
  color: #f05436;
  font-size: 12px;
}

.mind-map-lightRed {
  color: #f05436;
  font-size: 12px;
}

.form-error-msg {
  color: #f05436;
  font-size: 12px;
  margin-bottom: 10px;
  position: absolute;
}

.mm_container {
  position: relative;
}

.home_container_border {
  box-shadow: 0 0 10px white;
  border-radius: 25px;
  position: relative;
  margin-top: 80px;
  /* padding-top: 50px; */
  padding: 50px 30px 0 30px;
  height: auto;
  min-height: 330px;
  background-color: #09091d;
}

.home_container_border .img-cntnr {
  box-shadow: 0 0 10px white;
  border-radius: 50%;
  position: absolute;
  left: 42%;
  top: -40px;
  padding: 20px;
  background: #09091d;
}

.img-cntnr .home_img {
  height: 40px;
  width: auto;
}

.prompt_input {
  color: black;
  padding: 0.375rem 0.75rem;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 14px;
  border: 0;
  width: 100%;
  border-radius: 15px !important;
  box-shadow: 0 0 15px 5px #f05436;
}

.orange_glow {
  border-radius: 15px !important;
  box-shadow: 0 0 15px 5px #f05436;
}

.orange_glow:focus-visible {
  outline: none;
  box-shadow: 0 0 15px 5px #f05436 !important;
}

.orange_glow_input ::placeholder {
  color: white !important;
}

.orange_glow_input {
  border-radius: 15px !important;
  box-shadow: 0 0 15px 6px #f05436;
  border-radius: 10px;
  color: black;
  padding: 0.375rem 0.75rem;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 14px;
  border: 0;
  width: 100%;
}

.orange_glow_input:focus-visible {
  outline: none;
  box-shadow: 0 0 15px 5px #f05436 !important;
}

.orange_glow_file p {
  color: white !important;
  font-size: 14px;
  opacity: 0.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}

.orange_glow_file p.active {
  padding-right: 20px;
  opacity: 1;
  position: relative;
}

.orange_glow_file p.active span {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.orange_glow_file input {
  display: none;
}

.orange_glow_file {
  border-radius: 15px !important;
  box-shadow: 0 0 15px 6px #f05436;
  border-radius: 10px;
  padding: 0.375rem 0.75rem;
  padding-top: 12px;
  padding-bottom: 12px;
  border: 0;
  margin: 0 auto;
  width: 200px;
}

.orange_glow_file:focus-visible {
  outline: none;
  box-shadow: 0 0 15px 5px #f05436 !important;
}

.swiper-slide.swiper-slide-active .orange_glow_file {
  width: 280px;
}

.h2-tag {
  color: #f05436;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
}

.right-slider-mindmap {
  position: absolute;
  width: 40%;
  right: 0;
  min-height: 500px;
}

.right-slider-mindmap .slider-wrapper.axis-vertical {
  height: 500px !important;
}

.orange_text {
  color: #f05436;
}

.cursor_pointer {
  cursor: pointer;
}

.swiper-slide input,
.swiper-slide select,
.swiper-slide .orange_glow_file {
  border-radius: 15px;
  background: #09091d;
  width: 200px;
  margin: 0 auto;
  color: #fefefe;
}

.swiper-slide select,
.swiper-slide select option,
.swiper-slide .orange_glow_file {
  cursor: pointer;
}

.margin_left {
  margin-left: 110px;
}

.swiper-slide select option {
  background: #f00;
  color: #f00;
}

.mySwiper {
  height: 500px !important;
}

.mySwiper .swiper-slide {
  opacity: 0.8;
}

.mySwiper .swiper-slide.swiper-slide.swiper-slide-active {
  opacity: 1;
}

.mySwiper .swiper-scrollbar {
  background: linear-gradient(to bottom, #09091d 0%, rgba(255, 255, 255, 0.25) 50%, #09091d 100%) !important;
  width: 12px !important;
  border-radius: unset !important;
  /* padding: 2px 0px !important; */
  /* height: calc(100% - 4px) !important; */
}

.mySwiper .swiper-scrollbar .swiper-scrollbar-drag {
  background: white !important;
  width: calc(100% - 5px) !important;
  margin-left: 2.5px !important;
}

.swiper-slide.swiper-slide-active .orange_glow {
  width: 280px !important;
}

.swiper-slide.swiper-slide-active .orange_glow_input {
  width: 280px !important;
}

.mm_creation_frm .brain-area .orange_glow {
  /* -moz-appearance: none;
  /* Firefox */
  /* -webkit-appearance: none; */
  /* Safari and Chrome */
  appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, white 50%),
    linear-gradient(135deg, white 50%, transparent 50%), linear-gradient(to right, #0b0a0a, #000);
  background-position:
    calc(100% - 20px) calc(1em + 6px),
    calc(100% - 15px) calc(1em + 6px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    10px 2em;
  background-repeat: no-repeat;
}

.mySwiper .orange_glow {
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, white 50%),
    linear-gradient(135deg, white 50%, transparent 50%), linear-gradient(to right, #0b0a0a, #000);
  background-position:
    calc(100% - 20px) calc(1em + 6px),
    calc(100% - 15px) calc(1em + 6px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    10px 2em;
  background-repeat: no-repeat;
}

.mySwiper .swiper-slide h5 {
  margin: 0 auto;
  width: fit-content;
}

.form_second_title {
  margin-top: 50px;
}

.mySwiper1 {
  height: 50px !important;
}

.mySwiper1 .swiper-slide {
  opacity: 0.5;
}

.mySwiper1 .swiper-slide.swiper-slide-active {
  opacity: 1;
}

.mm_creation_frm {
  justify-content: normal;
  /* padding-left: 30px; */
  padding-bottom: 20px;
}

.mm_creation_frm .form-error-msg {
  position: relative !important;
}

.mySwiper .form-input-error-msg {
  width: 100%;
  text-align: center;
  color: #f05436;
  font-size: 12px;
  margin-bottom: 10px;
  position: absolute;
}

.mm_creation_frm .brain-area {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.mm_creation_frm .brain-area select {
  right: 20px;
  /* position: absolute; */
  background: transparent;
  border: 0;
  border-radius: 10px;
  width: 200px;
  color: #fefefe;
}

.mm_creation_frm .brain-area .navbar-login-button {
  background-color: #f05436 !important;
  color: white !important;
  padding: 8px 50px;
}

.mm_creation_frm .brain-area .navbar-login-button:hover {
  color: #f05436 !important;
  background-color: #09091d !important;
}

.customisation-tag h4 {
  font-size: 35px;
  padding-top: 10px;
}

.customisation-tag {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.customisation-tag span {
  font-size: 15px;
  line-height: 20px;
  color: #f05436;
  padding-left: 10px;
  max-width: 160px;
}

/* select-option */

.brain-area select * {
  color: red !important;
  background-color: red !important;
}

/* select-option */

/* react-select-styles */

.rc-select {
  border-radius: 15px !important;
  box-shadow: 0 0 15px 5px #f05436;
  height: 45px;
  background: #09091d;
  font-size: 14px;
}

.rc-select .rc-select__control {
  border-radius: 15px !important;
  height: 45px;
  outline: unset !important;
  border: none !important;
  box-shadow: none !important;
  background: #09091d;
}

.rc-select .rc-select__value-container {
  padding-right: 0;
}

.rc-select .rc-select__single-value {
  color: white;
}

.rc-select .rc-select__indicator {
  appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, white 50%),
    linear-gradient(135deg, white 50%, transparent 50%), linear-gradient(to right, #0b0a0a, #000);
  background-position:
    calc(100% - 20px) calc(1em + 6px),
    calc(100% - 15px) calc(1em + 6px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    10px 2em;
  background-repeat: no-repeat;
  height: 45px;
  width: 25px;
  cursor: pointer;
}

.rc-select .rc-select__indicator svg {
  display: none !important;
}

.rc-select .rc-select__indicator-separator {
  display: none;
}

.rc-select .rc-select__input-container {
  cursor: pointer;
  caret-color: transparent;
}

.rc-select .rc-select__input {
  color: white !important;
}

.rc-select__menu-list {
  padding-left: 20px !important;
}

.rc-select__menu {
  z-index: 10 !important;
  background: #09091d !important;
  box-shadow: 0 0 10px #f05436 !important;
}

.rc-select__option {
  background: none !important;
  color: white !important;
  position: relative !important;
  cursor: pointer !important;
}

.rc-select__option--is-selected::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url('/public/assets/images/check-icon.svg');
  background-size: 16px 16px;
  position: absolute;
  left: -8px;
  top: 10px;
}

/* react-select-styles */

/* area-select-styles */

.area-select {
  width: 275px;
}

.area-select .parent-option {
  font-weight: bold !important;
}

.area-select .child-option {
  font-weight: normal !important;
  padding-left: 30px;
  font-family: 'Poppins-normal', sans-serif !important;
}

/* area-select-styles */

/* customisations-select-attribute-styles */

.mySwiper .swiper-slide.swiper-slide-active {
  z-index: 1;
}

.customisations-select {
  width: 200px;
  margin: 1rem auto;
  justify-content: center;
  overflow: hidden;
}

.swiper-slide.swiper-slide-active .customisations-select {
  width: 280px;
  overflow: visible;
}
.mySwiper1 .swiper-slide .orange_text {
  font-size: 1rem;
}
/* .swiper-slide {
  height: 3rem !important;
} */
.mySwiper1 .swiper-slide {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 50px !important;
}

.customisations-select .rc-select__placeholder {
  color: white !important;
  font-size: 14px;
  opacity: 0.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.customisations-select .rc-select__menu {
  max-height: 200px;
  overflow-y: scroll;
}

/* customisations-select-attribute-styles */

@media only screen and (max-width: 767px) {
  .form-error-msg {
    position: relative;
  }

  .main-container {
    width: 65%;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .form-container {
    width: 100%;
    display: block;
  }

  .form-propmpt-input-div {
    width: 100%;
  }

  .form-button {
    width: 100%;
  }

  .fieldAraay {
    width: 100%;
  }

  .textArea-Div {
    width: 100%;
  }
}

@media only screen and (max-width: 595px) {
  .h1-tag {
    font-size: 38px;
  }

  .fieldAraay {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 350px) {
  .fieldAraay {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 1400px) {
  .mm_container .main-container {
    margin-left: 30px !important;
  }

  .form-container {
    width: 100% !important;
  }
}

@media only screen and (min-width: 1400px) {
  .experience-div {
    margin-top: 65px;
  }

  .form-button {
    width: auto;
    height: auto;
    padding: 0.8rem 4rem;
    margin-left: 1rem;
  }

  .form-container {
    width: 100% !important;
  }
}
