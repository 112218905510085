@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Poppins:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Poppins:wght@600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Poppins:ital,wght@0,600;0,700;1,600&display=swap');

.cp_co_form-containers {
  position: relative;
  margin-top: 114px;
}

.cp_co_form-main-container {
  margin: auto;
  max-width: 900px;
}

.cp_co_form-title-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.cp_co_form-title {
  width: 50%;
  color: #fefefe;
  font-size: 17px;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 2px;
  font-weight: 700;
}

.cp_co_form-body {
  position: relative;
  display: block;
}

.cp_co_form-content {
  position: relative;
  box-shadow: 0 0 10px white;
  background-color: #09091d;
  border-radius: 25px;
  height: auto;
  min-height: 300px;
  margin-top: 20px;
  padding: 40px 50px;
  padding-top: 20px;
  display: grid;
  gap: 30px;
}

.cp_co_form-element h4 {
  color: #f05436;
  font-size: 17px;
  line-height: 50px;
}

.cp_co_form-button-wrapper {
  padding-top: 40px;
  padding-bottom: 150px;
  display: flex;
  gap: 20px;
}

.cp_co_form-button-confirm {
  background-color: #f05436;
  color: white;
  padding: 8px 50px;
  border-radius: 10px;
  border: 1px solid #f05436;
}

.cp_co_form-button-confirm:hover {
  color: #f05436;
  background-color: #09091d;
}

.cp_co_form-element {
  position: relative;
}

.cp_co_form-element-wrapper {
  max-width: 400px;
}

.cp_co_form-element-wrapper.longText {
  max-width: unset;
}

.cp_co_form-element .cp_co_form-input-element {
  border-radius: 15px !important;
  box-shadow: 0 0 15px 6px #f05436;
  color: white;
  background: #09091d;
  padding: 0.75rem 1.5rem;
  font-size: 14px;
  border: 0;
  width: 100%;
  font-family: 'poppinsregular', sans-serif;
}

.cp_co_form-element .cp_co_form-input-element:focus-visible {
  outline: none;
  box-shadow: 0 0 15px 5px #f05436;
}

.cp_co_form-element .cp_co_form-textarea-element {
  border-radius: 15px;
  outline: none;
  box-shadow: 0 0 15px 5px #f05436;
  color: white;
  background: #09091d;
  padding: 1.5rem;
  font-size: 14px;
  border: 0;
  width: 100%;
  height: 140px;
  font-family: 'poppinsregular', sans-serif;
  resize: none;
}

.cp_co_form-element .cp_co_form-optional-label {
  margin-left: 10px;
  font-size: 12px;
  color: white;
  line-height: 18px;
}

.cp_co_form-rc-select {
  border-radius: 15px !important;
  box-shadow: 0 0 15px 5px #f05436;
  height: 45px;
  background: #09091d;
  font-size: 14px;
  font-family: 'poppinsregular', sans-serif;
}

.cp_co_form-rc-select .cp_co_form-rc-select__control {
  border-radius: 15px !important;
  height: 45px;
  outline: unset !important;
  border: none !important;
  box-shadow: none !important;
  background: #09091d;
}

.cp_co_form-rc-select .cp_co_form-rc-select__value-container {
  padding-left: 20px;
  padding-right: 0;
}

.cp_co_form-rc-select .cp_co_form-rc-select__single-value {
  color: white;
}

.cp_co_form-rc-select .cp_co_form-rc-select__indicator {
  appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, white 50%),
    linear-gradient(135deg, white 50%, transparent 50%), linear-gradient(to right, #0b0a0a, #000);
  background-position:
    calc(100% - 20px) calc(1em + 6px),
    calc(100% - 15px) calc(1em + 6px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    10px 2em;
  background-repeat: no-repeat;
  height: 45px;
  width: 25px;
  cursor: pointer;
}

.cp_co_form-rc-select .cp_co_form-rc-select__indicator svg {
  display: none !important;
}

.cp_co_form-rc-select .cp_co_form-rc-select__indicator-separator {
  display: none;
}

.cp_co_form-rc-select .cp_co_form-rc-select__input-container {
  cursor: pointer;
  caret-color: transparent;
}

.cp_co_form-rc-select .cp_co_form-rc-select__input {
  color: white !important;
}

.cp_co_form-rc-select__menu-list {
  padding-left: 20px !important;
}

.cp_co_form-rc-select__menu {
  z-index: 10 !important;
  background: #09091d !important;
  box-shadow: 0 0 10px #f05436 !important;
}

.cp_co_form-rc-select__option {
  background: none !important;
  color: white !important;
  position: relative !important;
  cursor: pointer !important;
  font-family: 'poppinsregular', sans-serif;
}

.cp_co_form-rc-select__option--is-selected::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url('/public/assets/images/check-icon.svg');
  background-size: 16px 16px;
  position: absolute;
  left: -8px;
  top: 10px;
}

@media only screen and (max-width: 991px) {
  .cp_co_form-main-container {
    max-width: unset;
    width: 90vw;
  }
}
