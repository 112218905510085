.cpd_containers {
  width: 250px;
  padding-top: 10px;
}

.cpd-rc-select {
  border-radius: 15px !important;
  box-shadow: 0 0 10px 2px white;
  height: 45px;
  background: #09091d;
  font-size: 14px;
  font-family: 'poppinsregular', sans-serif;
}

.cpd-rc-select .cpd-rc-select__control {
  border-radius: 10px !important;
  height: 45px;
  outline: unset !important;
  border: none !important;
  box-shadow: none !important;
  background: #09091d;
}

.cpd-rc-select .cpd-rc-select__value-container {
  padding-right: 0;
}

.cpd-rc-select .cpd-rc-select__single-value {
  color: white;
}

.cpd-rc-select .cpd-rc-select__indicator {
  appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, white 50%),
    linear-gradient(135deg, white 50%, transparent 50%), linear-gradient(to right, #0b0a0a, #000);
  background-position:
    calc(100% - 20px) calc(1em + 6px),
    calc(100% - 15px) calc(1em + 6px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    10px 2em;
  background-repeat: no-repeat;
  height: 45px;
  width: 25px;
  cursor: pointer;
}

.cpd-rc-select .cpd-rc-select__indicator svg {
  display: none !important;
}

.cpd-rc-select .cpd-rc-select__indicator-separator {
  display: none;
}

.cpd-rc-select__menu-list {
  padding-left: 20px !important;
}

.cpd-rc-select__menu {
  z-index: 10 !important;
  background: #09091d !important;
  box-shadow: 0 0 10px white !important;
}

.cpd-rc-select__option {
  background: none !important;
  color: white !important;
  position: relative !important;
  cursor: pointer !important;
  font-family: 'poppinsregular', sans-serif;
}

.cpd-rc-select__option--is-selected::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url('/public/assets/images/check-icon.svg');
  background-size: 16px 16px;
  position: absolute;
  left: -8px;
  top: 10px;
}

.cpd-rc-select-selected-option {
  color: white;
  font-family: 'poppinsregular', sans-serif;
  font-weight: bold;
  cursor: pointer;
}

.cpd-rc-select-selected-option img {
  width: 24px !important;
  margin-right: 10px !important;
}

.cpd-rc-select-option {
  font-family: 'poppinsregular', sans-serif;
  font-weight: bold;
  color: white;
  cursor: pointer;
  font-size: 14px;
}

.cpd-rc-select-option img {
  width: 24px !important;
  margin-right: 10px !important;
}

.cpd-rc-select-option.create img {
  width: 15px !important;
  margin-top: -1px !important;
  margin-right: 10px !important;
}
