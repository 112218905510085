/* .FormGroup {
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: white;
  will-change: opacity, transform;
  box-shadow:
    0 6px 9px rgba(50, 50, 93, 0.06),
    0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 black;
  border-radius: 4px;
} */

.stripe_height {
  height: calc(65vh - 47px);
}
.App {
  min-height: calc(100vh - 275px);
}
/* .FormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid #819efc;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

.payment-button {
  display: block;
  font-size: 16px;
  width: calc(100% - 30px);
  height: 40px;
  margin: 40px 15px 0;
  background-color: red;
  box-shadow:
    0 6px 9px rgba(50, 50, 93, 0.06),
    0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #ffb9f6;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: all 100ms ease-in-out;
  will-change: transform, background-color, box-shadow;
  border: none;
}

button:active {
  background-color: red;
  box-shadow:
    0 6px 9px rgba(50, 50, 93, 0.06),
    0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #e298d8;
  transform: scale(0.99);
}

.App {
  margin-left: 25%;
  margin-top: 5%;
  margin-right: 25%;
} */
/* 
h1,
h3 {
  text-align: center;
} */

/* .payment-success {
  color: green;
  text-align: center;
}
.Thank-you {
  color: black;
}
.error {
  color: red;
} */

.overlayDivForLogin {
  position: relative;
  width: 100%;
  height: 100%;
}
.overlayDivForLogin::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  cursor: pointer;
  right: 0;
  left: 0;
}
.overlayDivForSubscribed {
  position: relative;
  width: 100%;
  height: 100%;
}
.overlayDivForSubscribed::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  right: 0;
  left: 0;
}
