@import 'bootstrap/dist/css/bootstrap.min.css';

.selectBoxDiv {
  /* width: 100%; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 5px;
}

@media only screen and (max-width: 500px) {
  .selectBoxDiv {
    width: 100%;
    margin-bottom: 20px;
  }
}
