@import 'bootstrap/dist/css/bootstrap.min.css';
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@900&display=swap'); */

.mianDiv {
  display: flex;
  align-items: center;
}

.logoCircleDiv {
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.circletitleLogo {
  font-family: 'Poppins', sans-serif;
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f05436;
  background-color: #fff;
  border-radius: 50%;
  font-weight: 600;
  height: 85px;
  width: 85px;
  box-shadow: 0px 0px 28px white;
  text-align: center;
}

.circletitleLogo span {
  color: white;
  text-shadow:
    0px 0px 5px #f05436,
    0 0 1em #f05436,
    0 0 0.1em #f05436;
}

.h3Class {
  color: #f05436;
  font-size: 28px;
  font-weight: 800;
  font-style: normal;
  font-weight: 800;
  margin-top: auto !important;
  margin-bottom: 16px !important;
}

.searchBar {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.span {
  color: #fff;
  font-weight: 800;
  font-size: 30px;
  font-style: normal;
  margin-left: 5px;
}

.form {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  border: 0;
  width: 90%;
}

.input-style {
  border-radius: 18px;
  padding: 0.5rem 0.8rem;
  box-shadow: 0px 0px 18px #f05436;
}

@media only screen and (max-width: 500px) {
  .mianDiv {
    display: block;
    justify-content: center;
    align-items: center;
  }

  .searchBar {
    /* text-align: center;
    justify-content: center; */
    align-items: center;
  }

  .form {
    align-items: center;
    justify-content: center;
    display: flex;
  }
}

@media only screen and (max-width: 1400px) {
  .mianDiv {
    width: 100% !important;
    justify-content: space-between;
    align-items: center;
    margin: auto;
  }
}

@media only screen and (min-width: 1600px) {
  .mianDiv {
    justify-content: space-between;
    align-items: center;
    margin: auto;
  }
}

@media only screen and (max-width: 767px) {
  .searchBar {
    display: grid !important;
    width: unset !important;
  }

  .searchBar .form {
    margin: 0 !important;
  }
}
