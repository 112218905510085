@import 'bootstrap/dist/css/bootstrap.min.css';

.Payement_success_main_container {
  display: flex;
  height: 100vh;
  background-color: black;
}
.Payement_success_part1_container {
  width: 50%;
  background-color: black;
  color: white;
  align-items: center;
  margin: auto;
}
.Payement_success_part1_container_section {
  background-color: black;
}
.dull_white {
  color: #8c8c8c;
}
.Payement_success_part1_section {
  /* border: 2px solid white; */
  width: 80%;
  float: right;
  align-items: center;
  padding: 1rem;
}
.Payement_success_heading {
  display: flex;
  align-items: center;
}
.Payement_success_part2_container {
  width: 50%;
  align-items: center;
  margin: auto;
  background-color: white;
  height: 100vh;
  display: flex;
}
.Payement_success_ideon_logo {
  color: #f05436;
  border: 1px solid white;
  border-radius: 50%;
  font-size: 14px;
  padding: 20px 10px 20px 10px;
}
.my-dashboard-link {
  color: #f05436;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
}
.Payement_success_ideon_heading {
  font-size: 1.7rem;
  margin: 0px 1rem;
  text-decoration: none;
  color: white;
}
.try_basic {
  color: #8c8c8c;
  font-size: 1.5rem;
}
.try_basic_worth {
  color: #8c8c8c;
  font-size: 1.5rem;
}
.try_basic_section {
  margin: 2rem 0rem;
}
.Payement_success_footer {
  color: #8c8c8c;
  margin: 2rem 0rem;
}
.width_basic_70 {
  width: 60%;
}
.Payement_success_powered_by {
  border-right: 1px solid #8c8c8c;
  padding-right: 2rem;
}
.payment_success_font_size {
  font-size: 1.5rem;
  color: white;
}
.payment_success_font_size_gray {
  font-size: 1.5rem;
  color: #8c8c8c;
}
.success_payment_part2_heading_div {
  margin-top: -1rem;
}

.payment_seccess_part2_section {
  width: 60%;
  text-align: center;
  justify-content: center;
  margin: auto;
}
.try_basic_heading {
  font-size: 4.5rem;
}
.seccess_payment_stripe_card {
  position: relative;
  background-color: #d8d7d7;
  color: rgb(112, 112, 112);
  justify-content: center;
  margin: auto;
  padding: 2rem 1rem 1rem 1rem;
  margin-bottom: 30px;
}
.success_payment_stripechard_border {
  border-bottom: 1px dashed grey;
  width: 70%;
  text-align: left;
}
.seccess_payment_stripe_card:after {
  content: '';
  position: absolute;
  background: linear-gradient(-45deg, transparent 16px, #d8d7d7 0), linear-gradient(45deg, transparent 16px, #d8d7d7 0);
  background-repeat: repeat-x;
  background-position: left bottom;
  background-size: 22px 32px;
  content: '';
  display: block;
  width: 100%;
  height: 32px;
  top: 64px;
  left: 0px;
}
.success_payment_part2_paragraph {
  font-size: 1.5rem;
  color: #8c8c8c;
  margin: 2rem 0px;
}
.seccess_payment_stripe_card_text_size {
  font-size: 1.4rem;
}
.seccess_payment_stripe_card_text_gap {
  letter-spacing: 3px;
}

@media only screen and (max-width: 1400px) {
  .Payement_success_ideon_heading {
    font-size: 1.5rem;
  }
  .Payement_success_ideon_logo {
    font-size: 12px;
    padding: 15px 5px 15px 5px;
  }
  .try_basic {
    font-size: 1.1rem;
  }
  .try_basic_heading {
    font-size: 3rem;
  }
  .try_basic_worth {
    font-size: 1.1rem;
  }
  .payment_success_font_size {
    font-size: 1.1rem;
  }
  .payment_success_font_size_gray {
    font-size: 1.1rem;
    color: #8c8c8c;
  }
}
@media only screen and (max-width: 992px) {
  .Payement_success_main_container {
    display: grid;
  }
  .Payement_success_main_container {
    background-color: white;
  }
  .Payement_success_part1_container {
    width: 100%;
  }
  .Payement_success_part1_section {
    float: none;
    justify-content: center;
    margin: auto;
  }
  .Payement_success_part2_container {
    width: 100%;
  }
}
