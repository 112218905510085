@import 'bootstrap/dist/css/bootstrap.min.css';

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: auto;
}

.cp-spinner-wrapper {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 12;
}

.cp-spiner {
  font-size: 30px;
  height: 50px;
  width: 50px;
  z-index: 2;
  cursor: pointer;
  color: #f05436;
}

.cp-spinner-message {
  text-align: center;
  color: white;
  margin-top: 50px;
  font-size: 18px;
}

.cp-container {
}

.cp-content {
}
