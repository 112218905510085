@import 'bootstrap/dist/css/bootstrap.min.css';

.toggleBtnDiv {
  align-items: right;
  justify-content: flex-end !important;
  display: flex;
  padding-right: 20px;
}

.toggleBtn {
  background-color: #f05436;
  border-radius: 8px;
  border: none;
}

.togglelinkBtn {
  color: #f05436;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  text-decoration: none;
  height: 30px;
}

.linkBtnDiv {
  display: block;
  height: 30px;
  box-shadow: #f05436;
}
