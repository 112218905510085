@import 'bootstrap/dist/css/bootstrap.min.css';
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Poppins:ital,wght@0,600;1,300;1,500;1,600&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Poppins:ital,wght@0,600;1,300;1,500;1,600;1,700&display=swap'); */

.get_start_container {
  background-color: #ececec;
  width: 88%;
  justify-content: center;
  margin-bottom: 30px !important;
  margin: auto;
  border-radius: 12px;
}

.margin_getstarted_manager {
  margin-top: 8rem;
  margin-bottom: 8rem !important;
}

.white_getstarted_shadow_warper {
  background: white;
  /* padding: 20px 30px; */
  padding-right: 0px !important;
  padding-bottom: 10px !important;
  padding-top: 10px !important;
  margin-bottom: 8rem !important;
  border-radius: 12px;
  margin-bottom: 15px;
  position: relative;
  align-items: center;
  justify-content: space-between;
  gap: 100px;
}

.white_shadow_warper:after {
  content: '';
  width: 96%;
  height: 15px;
  background: #ececec;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -15px;
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
}

.margin_5 {
  margin-top: 5rem !important;
}

.width_getstarted_50 {
  width: 50% !important;
}

.sub_getstarted_title_drak {
  font-size: 48px;
  width: 75%;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
  color: #09091d;
}

.width_getstarted_40 {
  width: 30% !important;
}

.baner_getstarted_btn {
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 18px;
  background-color: #f05436;
  border: none;
  color: white;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
  border: 1px solid #f05436;
}

.baner_getstarted_btn:hover {
  color: #f05436;
  background-color: #09091d;
}

.white_getstarted_warper {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

@media screen and (max-width: 992px) {
  .responsive_getstarted_width {
    justify-content: center;
    margin: auto;
    width: 80% !important;
  }
}

@media screen and (max-width: 768px) {
  .responsive_getstarted_Image {
    justify-content: center;
    margin: auto;
    width: 70% !important;
  }

  .responsive_getstarted_width {
    width: 80% !important;
  }
}

@media only screen and (max-width: 769px) {
  .sub_getstarted_title_drak {
    width: 100% !important;
  }
}

@media only screen and (max-width: 600px) {
  .sub_getstarted_title_drak {
    font-size: 35px !important;
  }
}

@media only screen and (max-width: 992px) {
  .peta_getStarted_width {
    width: 90%;
  }
}

@media only screen and (min-width: 1600px) {
  .margin_getstarted_manager {
    margin-top: 8rem;
    margin-bottom: 8rem !important;
  }
}

.white_introducing_shadow_warper:after {
  content: '';
  width: 96%;
  height: 15px;
  background: #ececec;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -15px;
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
}
