/* Style for Workspace Edit */

.workspace_edit-container {
  width: 100vw;
  height: 100vh;
}

.workspace_edit-spinner-wrapper {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.workspace_edit-spinner {
  font-size: 30px;
  height: 50px;
  width: 50px;
  z-index: 2;
  cursor: pointer;
  color: #f05436;
}

.workspace_edit-spinner-message {
  text-align: center;
  color: white;
  margin-top: 50px;
  font-size: 18px;
}

.workspace_edit-top-navigation-bar {
  background: #09091d;
  position: relative;
  z-index: 111;
}

.workspace_edit-workspace-title {
  position: absolute;
  top: 20px;
  left: 200px;
  z-index: 111;
}

.workspace_edit-workspace-title h1 {
  color: white;
  font-size: 14px;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  margin-top: 4px;
  margin-bottom: 0;
}

.workspace_edit-workspace-title input {
  color: white;
  font-size: 14px;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  background: #09091d;
  border: unset;
  width: fit-content !important;
  min-width: 500px !important;
  padding: 0 !important;
  margin: 0 !important;
  outline: none !important;
}

.workspace_edit-workspace-title input:focus-visible {
  outline: none !important;
}

.workspace_edit-diagram-wrapper {
  width: 100%;
  margin-top: -117px;
  height: calc(100% + 51px);
  background-color: #f0f0f0;
  background: #09091d;
}

.workspace_edit-diagram {
  width: 100%;
  height: 100%;
}

.workspace_edit-diagram textarea {
  padding: 5px 10px !important;
  border-radius: 5px !important;
}

.workspace_edit-abm-wrapper {
  position: absolute;
  z-index: 99;
  bottom: 25px;
  right: 25px;
  filter: drop-shadow(0 1px 4px white);
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f05436;
  border-radius: 50px;
}

.workspace_edit-abm-toggle {
  padding: 10px 8px;
  background: white;
  border-radius: 100%;
}

.workspace_edit-abm-buttons {
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow: hidden;
  height: 0;
  transition: 1.3s cubic-bezier(0.53, 0, 0.15, 1.3);
}

.workspace_edit-abm-buttons span {
  padding: 8px 9px;
  color: white;
  cursor: pointer;
  margin: auto;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  transform: rotateY(90deg);
}

.workspace_edit-abm-buttons.expanded span,
.workspace_edit-abm-buttons.expanded-half span {
  width: fit-content;
  opacity: 1;
  visibility: visible;
  transform: rotateY(0deg);
}

.workspace_edit-abm-buttons span svg {
  height: 20px;
  width: 20px;
}

.workspace_edit-abm-buttons.expanded {
  height: 368px;
  padding: 10px 0;
}

.workspace_edit-abm-buttons.expanded-half {
  height: 188px;
  padding: 10px 0;
}

.workspace_edit-abm-buttons span:nth-of-type(1) {
  transition-delay: 1.1s;
}

.workspace_edit-abm-buttons span:nth-of-type(2) {
  transition-delay: 1s;
}

.workspace_edit-abm-buttons span:nth-of-type(3) {
  transition-delay: 0.9s;
}

.workspace_edit-abm-buttons span:nth-of-type(4) {
  transition-delay: 0.8s;
}

.workspace_edit-abm-buttons span:nth-of-type(5) {
  transition-delay: 0.7s;
}

.workspace_edit-abm-buttons span:nth-of-type(6) {
  transition-delay: 0.6s;
}

.workspace_edit-abm-buttons span:nth-of-type(7) {
  transition-delay: 0.5s;
}

.workspace_edit-abm-buttons span:nth-of-type(8) {
  transition-delay: 0.4s;
}

.workspace_edit-abm-buttons span:nth-of-type(4) {
  transition-delay: 0.8s;
}

.workspace_edit-abm-buttons.expanded span:nth-of-type(1),
.workspace_edit-abm-buttons.expanded-half span:nth-of-type(1) {
  transition-delay: 0.4s;
}

.workspace_edit-abm-buttons.expanded span:nth-of-type(2),
.workspace_edit-abm-buttons.expanded-half span:nth-of-type(2) {
  transition-delay: 0.5s;
}

.workspace_edit-abm-buttons.expanded span:nth-of-type(3),
.workspace_edit-abm-buttons.expanded-half span:nth-of-type(3) {
  transition-delay: 0.6s;
}

.workspace_edit-abm-buttons.expanded span:nth-of-type(4),
.workspace_edit-abm-buttons.expanded-half span:nth-of-type(4) {
  transition-delay: 0.7s;
}

.workspace_edit-abm-buttons.expanded span:nth-of-type(5),
.workspace_edit-abm-buttons.expanded-half span:nth-of-type(5) {
  transition-delay: 0.8s;
}

.workspace_edit-abm-buttons.expanded span:nth-of-type(6),
.workspace_edit-abm-buttons.expanded-half span:nth-of-type(6) {
  transition-delay: 0.9s;
}

.workspace_edit-abm-buttons.expanded span:nth-of-type(7),
.workspace_edit-abm-buttons.expanded-half span:nth-of-type(7) {
  transition-delay: 1s;
}

.workspace_edit-abm-buttons.expanded span:nth-of-type(8),
.workspace_edit-abm-buttons.expanded-half span:nth-of-type(8) {
  transition-delay: 1.1s;
}

.workspace_edit-workspace-export-title {
  position: absolute;
  z-index: 99;
  top: 5px;
  left: 50%;
  transform: translateX(-50%);
}

.workspace_edit-workspace-export-title h3 {
  color: white;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  text-shadow: 2px 2px 10px #fff;
}

.workspace_edit-workspace-export-buttons {
  position: absolute;
  right: 25px;
  bottom: 25px;
  z-index: 99;
  display: flex;
  gap: 20px;
  height: fit-content;
  width: fit-content;
}

.workspace_edit-workspace-export-buttons .export-checkbox {
  color: white;
  margin: auto;
  display: flex;
  gap: 10px;
  cursor: pointer;
}

.workspace_edit-workspace-export-buttons .export-checkbox .checked,
.workspace_edit-workspace-export-buttons .export-checkbox .unchecked {
  margin: auto;
}

.workspace_edit-workspace-export-buttons .export-checkbox span {
  cursor: pointer;
}

.workspace_edit-workspace-export-buttons .export-button {
  background-color: white;
  color: #f05436;
  font-weight: bold;
  font-size: 20px;
  border-radius: 25px;
  padding: 8px 20px;
  box-shadow: 0px 0px 15px white;
  border: none;
}

@media only screen and (min-width: 1600px) {
  .workspace_edit-workspace-title h1 {
    margin-top: 5px;
  }
}

/* Style for More Actions Menu */

.more_actions_modal-container {
  position: absolute;
  top: 80px;
  right: 0;
  width: 50vw;
  height: calc(100vh - 120px);
  display: flex;
  background: #09091d;
  box-shadow: 0px 0px 18px white;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  z-index: 999;
}

.more_actions_modal-sidebar {
  width: 220px;
  height: 100%;
  border-right: 1px solid rgba(194, 194, 194, 0.25);
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.more_actions_modal-sidebar h4 {
  color: #f05436;
  font-size: 26px;
  padding: 10px 15px 10px 20px;
}

.more_actions_modal-detailed-view {
  width: calc(100% - 220px);
  height: 100%;
}

.more_actions_modal-sidebar-menu {
  padding: 20px 0;
}

.more_actions_modal-sidebar-menu span {
  color: #c2c2c2;
  font-size: 10px;
  margin: 0 20px;
}

.more_actions_modal-sidebar-menu p {
  color: white;
  font-size: 16px;
  padding: 2px 20px;
  margin-bottom: 5px !important;
  cursor: pointer;
  display: flex;
  gap: 10px;
}

.more_actions_modal-sidebar-menu p img {
  /* width: 12px;
  height: 12px; */
  margin: auto 0;
}

.more_actions_modal-sidebar-menu p:last-child {
  color: #f05436;
}

.more_actions_modal-sidebar-menu p:hover:not(:last-child) {
  background: #f05436;
  box-shadow: 0px 0px 10px #f05436;
}

.more_actions_modal-sidebar-menu p.active {
  background: #f05436;
  box-shadow: 0px 0px 10px #f05436;
}

.more_actions_modal-sidebar-menu hr {
  margin: 10px 20px !important;
  padding: 0 !important;
  border-color: #c2c2c2;
}

.more_actions_modal-close-button {
  background: #09091d;
  border: 1px solid #f05436;
  color: #f05436;
  border-radius: 5px;
  font-size: 14px;
  font-weight: normal;
  padding: 5px 20px;
  width: calc(100% - 30px);
  margin-left: 15px;
  margin-top: auto;
}

.more_actions_modal-close-button svg {
  margin-top: -2px;
}

.export_button_wrapper {
  position: absolute;
  z-index: 99;
  top: 90px;
  right: 25px;
  filter: drop-shadow(0 1px 4px white);
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f05436;
  border-radius: 13px;
  min-width: 162px;
}

.export_button_wrapper button {
  filter: drop-shadow(0px 2px 1px white);
  border-radius: 13px;
  color: #f05436;
  padding: 5px 20px;
  border: none;
  display: inline-block;
  background: white;
  padding: 10px 25px;
}
.export_sub_button_wrapper.open {
  display: flex !important;
}
.export_sub_button_wrapper {
  display: none;
  flex-direction: column;
  gap: 15px;
  padding: 10px 10px 10px;
}

.export_sub_button_wrapper span {
  color: white;
  cursor: pointer;
}

.export_sub_button_wrapper span img {
  height: 22px;
  width: auto;
  margin-right: 10px;
}
