@import 'bootstrap/dist/css/bootstrap.min.css';

.textArea {
  border-radius: 10px;
  color: black;
  padding: 0.375rem 0.75rem;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 14px;
  border: 0;
  width: 100%;
  height: 80px;
  box-shadow: rgb(240, 84, 54) 0px 0px 7px 0px;
}

.textArea:focus-visible {
  outline: none;
}

.textArea:focus {
  outline: none !important;
}
