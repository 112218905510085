.challenges_wrapper {
    margin: 200px auto;
}

.home_challenges-title {
    font-size: 30px;
    color: #f05436;
}

.home_challenges-block-wrapper {
    gap: 62px;
    display: flex;
    margin-top: 50px;
}

.home_challenges-block-wrapper .block_title {
    color: white;
    text-shadow: 0px 0px 5px #f05436,
        0 0 1em #f05436,
        0 0 0.1em #f05436;
    margin-top: 11px;
    min-height: 67px;
    font-size: 25px;
}

.home_challenges-block-wrapper .block_text {
    font-family: 'Poppinsregular';
    margin-top: 20px;
    text-align: left;
}

.home_challenges-block {
    border-radius: 40px;
    min-height: unset;
    box-shadow: 0 0 18px white;
    background-color: #09091d;
    display: block;
    align-items: center;
    justify-content: space-between;
    padding: 2.1vw;
    text-align: center;
    flex: 1;
}

.home_challenges-block img {
    min-height: 75px;
}



@media (max-width:992px) {
    .challenges_wrapper {
        margin: 50px auto;
    }

    .home_challenges-title {
        font-size: 24px;
    }

    .challenges_wrapper .block_title {
        min-height: auto;
    }

    .home_challenges-block-wrapper {
        margin-top: 30px;
        flex-direction: column;
        gap: 50px;
    }

    .home_challenges-block .block_text {
        margin-top: 10px;
    }

    .home_challenges-block {
        padding: 40px
    }
}

@media (max-width:767px) {
    .home_challenges-block {
        padding: 16px 31px 30px;
    }

    .home_challenges-title {
        font-size: 15px;
    }

    .challenges_wrapper .block_title {
        font-size: 20px;
    }

    .home_challenges-block .block_text {
        font-size: 12px;
    }
}