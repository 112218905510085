.md_section_founder_story {
  padding: 150px 0;
}
.md_section_founder_story .md_business_text.md_know_text.revolution_title {
  margin-bottom: 64px;
}
.md_section_founder_story .md_business_text.md_know_text.revolution_title::after {
  content: 'Founders story';
}
.md_section_founder_story .md_section_wrapper_info_section_reverse {
  padding: 0 0 150px 0;
}
.md_section_founder_story .text_btns_div_p {
  font-size: 18px;
}
.md_section_founder_story .md_hero_banner_img {
  overflow: hidden;
}

@media (max-width: 995px) {
  .md_section_founder_story .md_section_wrapper_info_section,
  .md_section_founder_story .md_section_wrapper_info_section_reverse {
    display: flex !important;
    flex-direction: column-reverse;
  }
  .md_business_text.md_know_text.revolution_title::after {
    right: 0;
  }
  .md_section_founder_story {
    padding-bottom: 0;
    text-align: center;
  }
  .md_section_founder_story .md_section_wrapper_info_section_reverse_one,
  .md_section_founder_story .md_section_wrapper_info_section_one {
    padding-right: 0;
    margin-top: 20px;
  }
  .md_section_founder_story .md_section_wrapper_info_section_reverse {
    padding-bottom: 70px;
  }
}
