/* @import 'bootstrap/dist/css/bootstrap.min.css'; */

.loginBtn {
  background-color: #f05436;
  border-radius: 10px;
  width: 100%;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  border: 0;
  font-weight: 900;
  margin: auto;
  justify-content: center;
  display: block;
  margin-top: 18px;
}

.submitButton {
  background-color: #f05436;
  border-radius: 10px;
  width: 100%;
  font-size: 17px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  font-weight: 700;
  margin-top: 2rem;
  border: 2px solid transparent;
}

.submitButton:hover {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  font-weight: 800;
  background-color: #09091d;
  border: 2px solid #f05436;
  color: #f05436;
}

@media only screen and (max-width: 600px) {
  .submitButton {
    font: 13px !important;
  }
}
