@import 'bootstrap/dist/css/bootstrap.min.css';

.contact_page {
  min-height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-page-container {
  width: 100%;
  justify-content: space-around;
  margin: auto;
  display: flex;
  align-items: top;
  padding: 3.5rem 0px;
  margin-top: 10px;
}

@media only screen and (max-width: 800px) {
  .contact-page-container {
    width: 100%;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .contact_page {
    min-height: 100%;
  }
}

@media only screen and (min-width: 1400px) {
  .contact-page-container {
    width: 1270px !important;
    justify-content: center !important;
  }
}

@media only screen and (min-width: 1500px) and (max-width: 2000px) {
  .contact_page {
    min-height: 100% !important;
  }
}
