@import 'bootstrap/dist/css/bootstrap.min.css';
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Poppins:ital,wght@0,600;1,300;1,500;1,600&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Poppins:ital,wght@0,600;0,700;1,600&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Poppins:ital,wght@0,600;0,700;1,600&display=swap'); */

.darkBlack {
  /* background: #09091d; */
}

.navbar-togglebar-icon {
  background-color: #f05436 !important;
}

.home_layout_margin_left {
  margin-left: 2.5rem !important;
  gap: 0.5rem !important;
}

.dropdown-menu[data-bs-popper] {
  right: 0 !important;
  left: auto !important;
}

.titleLogoTextHomePage {
  color: #f05436;
  font-size: 25px;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
  margin-left: 23px;
}

.titleLogoTextHomePage span {
  color: white;
  text-shadow:
    0px 0px 5px #f05436,
    0 0 1em #f05436,
    0 0 0.1em #f05436;
}

.nav-item .nav-dropdown {
  border: 10px solid red;
}

.navbar-bg-class {
  /* background: #09091d; */
  width: 92% !important;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.navbar-navs-link {
  margin-top: 0px;
  align-items: center;
  text-decoration: none;
  border: none;
  outline: none;
  padding: 10px 14px;
  cursor: pointer;
  color: #fefefe;
  font-weight: 600 !important;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
}

.navbar-dropdown {
  border: none;
  border: 0px;
  border-radius: 0%;
  color: white !important;
  /* margin-right: 33px; */
}

.navbar-login-div {
}

.navbar-login-link {
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  color: white;
}

.navbar-login-button {
  background-color: #09091d;
  padding: 8px 15px;
  margin-right: 35px;
}

.navbar-login-button:nth-child(1),
.navbar-login-button:nth-child(2) {
  background-color: #f05436;
  margin-right: 15px !important;
  border-radius: 10px;
  color: white;
  border: 1px solid #f05436;
}

.navbar-login-button:nth-child(1):hover,
.navbar-login-button:nth-child(2):hover {
  background-color: #09091d;
  color: #f05436;
}

.navbar-login-button:nth-child(3) {
  margin-right: 15px !important;
  border-radius: 10px;
  color: #f05436;
  background-color: #09091d;
  border: 1px solid #f05436;
}

.navbar-login-button:nth-child(3):hover {
  background-color: #f05436;
  color: white;
}

/* .navbar-login-button:hover {
  background-color: #f05436 !important;
  border-radius: 5px;
} */

.hover-class {
  margin-top: 0px;
  /* margin: 0px 10px; */
}

.hover-class :hover {
  color: #f05436;
}

.active-link {
  color: #f05436;
  margin-top: 5px;
  align-items: center;
  text-decoration: none;
  border: none;
  outline: none;
  padding: 10px 14px;
  cursor: pointer;
  font-weight: 600 !important;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
}

@media screen and (max-width: 991px) {
  .dropdown-menu[data-bs-popper] {
    right: auto !important;
    left: 0px !important;
  }

  .custom-dropdown .navbar-dropdown {
    margin-left: 50px;
    margin-top: 10px;
  }

  .navbar-dropdown {
    margin-left: 50px;
    margin-top: 0px;
  }

  .navbar-login-div {
    margin-left: 50px;
    margin-top: 15px;
    margin-bottom: 0px !important;
  }

  .custom-dropdown .dropdown-menu.show {
    background: rgb(41 41 67);
    width: fit-content;
  }

  .custom-dropdown .dropdown-menu a {
    color: #fff;
  }
}

@media only screen and (min-width: 1400px) {
  .navbar-bg-class {
    width: 1300px !important;
  }

  .titleLogoTextHomePage {
    margin-left: 5px;
  }

  .navbar-login-button {
    margin-right: 0px;
  }
}

.nav-dropdown .dropdown-toggle.nav-link {
  color: #fefefe !important;
  padding: 4px 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 14px;
}

.navbar-nav .nav-dropdown .nav-link.active,
.navbar-nav .nav-dropdown .nav-link.show {
  color: #fefefe !important;
}

.navbar-nav .nav-dropdown .nav-link:focus {
  box-shadow: none;
}

.nav-dropdown .nav-link:focus-visible {
  box-shadow: none;
}

@media only screen and (max-width: 962px) {
  .navigation-bar-cont .navbar-login-button:nth-child(2) {
    display: none;
  }
}
@media only screen and (min-width: 962px) {
  .navigation-bar-cont .navbar-login-button:nth-child(1) {
    display: none;
  }
}
