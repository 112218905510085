@import 'bootstrap/dist/css/bootstrap.min.css';

.darkGrayBg {
  background-color: #ededed;
  padding: 25px 0px 50px 0px;
}

.links-btns {
  color: black;
  text-decoration: none;
  font-size: 14px !important;
}

.links-btns:hover {
  color: #f05436;
}

.footer_width {
  width: 90% !important;
}

.footer-logo-text {
  font-size: 28px;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
  color: #09091d;
  margin-bottom: 0.8rem;
}

.footer_container {
  width: 90%;
}

.footer-text {
  color: #262626;
  font-size: 14px;
  line-height: 0px;
  margin-bottom: 20px;
}

.responsive_Footer {
  padding-top: 2rem;
  padding-bottom: 3rem;
}

@media screen and (max-width: 580px) {
  .responsive_Footer {
    text-align: center;
  }

  .darkGrayBg {
    padding: 25px 0 0 0;
  }

  .footer_container {
    width: 100%;
  }
}

@media only screen and (min-width: 1600px) {
  .footer_width {
    width: 65% !important;
  }
}
