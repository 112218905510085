.maap-card-container {
  display: flex;
  justify-content: space-between;
  background-color: #09091d;
}

.maap-card-action-button,
.maap-card-delete-button {
  width: 20px;
  margin: auto 0;
  cursor: pointer;
}

.maap-card-action-button {
  margin-right: 20px;
}

.maap-card-action-button svg {
  width: 20px;
}

.maap-card-delete-button {
  margin-left: 20px;
}

.maap-card {
  box-shadow: 0px 0px 8px white;
  padding: 12px 20px;
  border-radius: 15px;
  width: 100%;
  cursor: pointer;
}

.maap-card-header {
  color: #f05436;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}

.maap-card-header p {
  padding: 0;
  margin: 0;
}

.maap-card-header svg {
  cursor: pointer;
}

.maap-card-description {
  padding: 10px 0;
  display: grid;
  gap: 20px;
}

.maap-card-description h5 {
  color: white;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 5px;
}

.maap-card-description p {
  color: white;
  font-size: 12px;
  font-family: poppinsregular, Helvetica, Arial, sans-serif;
  font-weight: normal;
  margin-top: 2px;
  margin-bottom: 0px;
}

.maap-card-description p a {
  color: white;
}

.maap-card-edit-form-title-input {
  color: #f05436;
  font-size: 16px;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  background: #09091d;
  border: unset;
  width: calc(100% - 75px) !important;
  padding: 0 !important;
  margin: 0 !important;
  outline: none !important;
}

.maap-card-edit-form-title-input:focus-visible {
  outline: none !important;
}

.maap-card-edit-form-action-button {
  display: flex;
  gap: 6px;
}

.maap-card-edit-form-description-input {
  color: white;
  font-size: 12px;
  font-family: poppinsregular, Helvetica, Arial, sans-serif;
  font-weight: normal;
  text-decoration: none;
  background: #09091d;
  border: unset;
  width: 100% !important;
  padding: 0 !important;
  outline: none !important;
  resize: none !important;
  margin: 0 !important;
}

.maap-card-edit-form-description-input:focus-visible {
  outline: none !important;
}
