@import 'bootstrap/dist/css/bootstrap.min.css';
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Poppins:ital,wght@0,600;1,300;1,500;1,600&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Poppins:ital,wght@0,600;1,300;1,500;1,600;1,700&display=swap'); */

.workspace_modal_main_container {
  background-color: white;
  top: 1rem;
  left: 2rem;
  position: absolute;
  width: 20%;
  height: 95%;
  border-radius: 2rem;
  padding: 1.7rem 1.2rem;
  -webkit-box-shadow: -2px 0px 31px -10px rgba(255, 255, 255, 1);
  -moz-box-shadow: -2px 0px 31px -10px rgba(255, 255, 255, 1);
  box-shadow: -2px 0px 31px -10px rgba(255, 255, 255, 1);
}

.workspace_hearder {
  display: flex;
  justify-content: space-between;
}

.ideon_modal_heading {
  color: #f05436;
  font-size: 20px;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
}

.current_workspace_background_color {
  background-color: #09091d;
  border-radius: 1rem;
  padding: 1rem 0.8rem 0.5rem 0.8rem;
  align-items: center;
}

.current_workspace_heading_font {
  color: #f05436;
  font-size: 21px;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  line-height: 1rem;
}

.idean_person_icon_text {
  font-size: 1.6rem;
}

.current_workspace_text {
  font-size: 8px;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
  line-height: 5px;
}

.myworkspace_button {
  background-color: #f05436;
  padding: 10px 15px;
  width: 100%;
  color: white;
  margin-top: 15rem;
  margin-bottom: 11rem;
  border: none;
  border-radius: 0.5rem;
  font-weight: 600;
}

@media only screen and (max-width: 1200px) {
  .workspace_modal_main_container {
    width: 25%;
  }
}

@media only screen and (max-width: 992px) {
  .workspace_modal_main_container {
    width: 30%;
  }
}

@media only screen and (max-width: 768px) {
  .workspace_modal_main_container {
    width: 40%;
  }
}

@media only screen and (max-width: 605px) {
  .workspace_modal_main_container {
    width: 50%;
  }

  .current_workspace_background_color {
    padding: 1rem 0.5rem 0.5rem 0.5rem;
  }

  .myworkspace_button {
    padding: 5px 13px;
  }

  .current_workspace_heading_font {
    font-size: 18px;
  }
}

@media only screen and (max-width: 400px) {
  .workspace_modal_main_container {
    width: 60%;
  }

  .current_workspace_background_color {
    padding: 1rem 0.5rem 0.5rem 0.5rem;
  }

  .myworkspace_button {
    padding: 5px 10px;
  }

  .current_workspace_heading_font {
    font-size: 15px;
  }
}
