.new_getstarted .md_get-title {
    font-size: 40px;
    line-height: 1.2;
    font-family: "poppinssemibold";

}

.new_getstarted .md_text_btns a {
    font-size: 15px;
    text-transform: initial;
    position: relative;
    text-align: center;
    padding: 11px 0 5px;
    border-radius: 10px;
    width: 240px;
    font-family: 'poppinssemibold';

}

.new_getstarted .md_number_wrapper {
    padding: 40px 53px;
}

.new_getstarted .md_list {
    font-family: 'poppinssemibold';
}

.mobile_list {
    display: none;
}

.mobile_list li,
.md_list li {
    display: flex;
    align-items: center;
}

@media (max-width:992px) {
    .home_advantage-block {
        margin-bottom: 100px;
    }

    .new_getstarted .md_number_wrapper {
        padding: 30px 43px;
    }
}

@media (max-width:767px) {
    .new_getstarted .md_get-title {
        font-size: 20px;
        text-align: left;
    }

    .desktop_list {
        display: none;
    }

    .mobile_list {
        display: block;
    }

    .new_getstarted .md_text_btns a {

        width: 100%;
    }

    .new_getstarted .md_text_btns .trial_btn {
        margin-bottom: 0;
    }

    .new_getstarted .md_getstartted_wrapper .text_btns_div_p {
        font-size: 12px !important;
    }

    .new_getstarted .md_getstartted_wrapper .text_btns_div_p {
        text-align: left;
    }

    .new_getstarted .md_number_wrapper {
        padding: 25px 20px;
    }
}