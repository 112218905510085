@import 'bootstrap/dist/css/bootstrap.min.css';

.modal-container {
  background-color: #09091d;
  box-shadow: 0px 0px 18px white;
  width: 20%;
  position: absolute;
  right: 2rem;
  border-radius: 13px;
  padding: 20px;
  z-index: 1;
}

.modal-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-heading-title {
  font-size: 16px;
  font-weight: 600;
  color: #f05436;
}

.modal-heading-btn {
  border: none;
  background: none;
  padding-bottom: 10px;
}

.modal-body-form-container {
  box-shadow: 0px 0px 5px white;
  background-color: white;
  margin-top: 20px;
  padding: 8px;
  border-radius: 8px;
}

.modal-body-title {
  font-size: 16px;
  font-weight: 600;
  color: #f05436;
  margin: 0px;
  margin-bottom: 10px;
}

.modal-body-p {
  font-size: 12px;
  font-weight: 600;
  margin: 0px;
  margin-bottom: 3px;
  pad: 0px;
  color: #09091d;
}

.modal-body-form {
  margin-top: 80px;
}

.modal-form {
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 10px;
}

.modal-input-div {
  align-items: center;
}

.modal-input-style {
  border-radius: 18px;
  padding: 0.2rem 0.4rem;
}

.modal-form-btn {
  background-color: #f05436;
  border: none;
  border-radius: 10px;
  color: white;
  font-size: 12px;
  font-weight: 600;
  padding: 5px 10px;
}

.modal-body-attachment-container {
  box-shadow: 0px 0px 5px white;
  background-color: white;
  margin-top: 40px;
  padding: 8px;
  border-radius: 8px;
}

.modal-attachment-plus-icon {
  border-radius: 50%;
  padding: 5px;
  box-shadow: 0px 0px 18px #f05436;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f05436;
  text-align: center;
  cursor: pointer;
}

@media screen and (max-width: 1100px) {
  .modal-container {
    width: 24%;
  }
}

@media screen and (max-width: 850px) {
  .modal-container {
    right: 2rem;
    width: 30%;
  }
}

@media screen and (max-width: 690px) {
  .modal-container {
    right: 2rem;
    width: 40%;
  }
}

@media screen and (max-width: 510px) {
  .modal-container {
    right: 2rem;
    width: 50%;
  }
}

@media screen and (max-width: 400px) {
  .modal-container {
    right: 1rem;
    width: 66%;
  }
}

@media screen and (max-width: 300px) {
  .modal-container {
    right: 1rem;
    width: 80%;
  }

  .modal-form {
    display: block;
  }
}
