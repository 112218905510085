@import 'bootstrap/dist/css/bootstrap.min.css';

/* @keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .moving-text {
    animation: marquee 15s linear infinite;
  }
} */
.logo_slider_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.steps_section .img_container {
  /* width: auto; */
  /* height: 50px; */
}

.steps_section .slider-container img {
  /* width: auto; */
  height: 40px;
  /* padding: 0 85px; */
}

.steps_section .slider-container {
  /* max-width: 95%; */
  margin: 0 auto;
  /* pointer-events: none; */
}

.logo-slider .swiper-wrapper {
  transition-timing-function: linear;
}

@media only screen and (max-width: 767px) {
  .steps_section .slider-container img {
    height: 20px;
    padding: 0 50px;
  }
}
