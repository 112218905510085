.advantage_wrapper {
  margin: 200px auto;
}

.home_advantage-title {
  font-size: 30px;
  color: #f05436;
}

.home_advantage-block-wrapper {
  margin-top: 30px;
}

.home_advantage-block-wrapper .content_wrapper {
  max-width: 479px;
}

.home_advantage-block {
  margin-bottom: 180px;
}

.home_advantage-block > div {
  display: flex;
  justify-content: center;
  font-family: 'Poppins';
}

.home_advantage-block .block_text {
  margin-top: 20px;
  font-family: 'poppinsregular';
}

.advantage_wrapper .block_title {
  font-size: 30px;
  line-height: 1.4;
  color: white;
  font-family: 'Poppins';
  text-shadow:
    0px 0px 5px #f05436,
    0 0 1em #f05436,
    0 0 0.1em #f05436;
}

.advantage_wrapper img {
  width: 100%;
  max-width: 400px;
  height: auto;
}

.advantage_wrapper .image-width {
  max-width: 300px;
}

@media (max-width: 992px) {
  .advantage_wrapper {
    margin: 80px auto;
  }

  .home_advantage-title {
    font-size: 20px;
  }

  .home_advantage-block {
    margin-bottom: 100px;
  }

  .advantage_wrapper .block_title {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .home_advantage-title {
    font-size: 15px;
  }

  .home_advantage-block .block_text {
    font-size: 12px;
  }

  .advantage_wrapper img {
    max-width: 260px;
  }
}
