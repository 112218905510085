@import 'bootstrap/dist/css/bootstrap.min.css';
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,600;1,300;1,500;1,600&display=swap'); */

body {
  background: #09091d;
  height: 100%;
}

.reset_logo_div {
  padding-left: 39px;
  padding-top: 17.5px;
}

.reset-password-lightRed {
  color: #f05436;
  margin: 0px;
}

.reset_password_width {
  width: 40%;
}

.mTop {
  margin-top: 45px;
}

.reset_password_title {
  color: #f05436;
  font-family: 'Poppins', sans-serif;
  font-size: 48px;
  margin-bottom: 10px;
}

.page-height {
  height: 100vh;
  background-color: #09091d;
  /* min-height: 100%; */
}

.fontSize {
  font-size: 25px;
}

.fontFamily {
  font-family: 'Poppins', sans-serif;
}

.fontSize30 {
  font-size: 30px;
}

.reset_background {
  background: #09091d;
}

.titleLogo {
  color: #f05436;
  font-size: 25px;
  font-weight: 900;
  font-family: 'Poppins', sans-serif;
  width: 93%;
  padding-top: 22px;
  justify-content: center;
  margin: auto;
  margin-left: 83px;
}

.expire-title-class {
  text-align: center;
  align-items: center;
  color: #fff;
  margin-top: 20px;
}

@media only screen and (min-width: 1500px) {
  .reset_logo_div {
    /* width: 80%; */
    width: 1500px;
    justify-content: center;
    margin: auto;
  }
}

@media only screen and (min-width: 1100px) {
  .reset_password_width {
    max-width: 450px;
  }
}

@media only screen and (max-width: 992px) {
  .reset_password_width {
    width: 450px;
  }
}

@media only screen and (max-width: 440px) {
  .reset_password_width {
    width: 300px;
  }

  .reset_password_title {
    font-size: 35px;
  }
}
