/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Poppins:ital,wght@0,600;0,700;1,600&display=swap'); */

.titleLogoText {
  color: #f05436;
  font-size: 25px;
  font-family: 'Inter', sans-serif;
  font-family: 'Poppins', sans-serif;
  width: 100%;
  justify-content: center;
  margin: auto;
  display: inline;
}

.titleLogoText span {
  color: white;
  text-shadow:
    0px 0px 5px #f05436,
    0 0 1em #f05436,
    0 0 0.1em #f05436;
}
