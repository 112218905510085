.terms_bg {
  background: transparent !important;
}
.terms_bg::after {
  content: none !important;
}
.terms_bg p,
.terms_bg li,
.terms_bg a {
  color: white;
  text-decoration: none;
}
.terms_bg a:hover {
  color: #fbb;
  text-decoration: underline;
}
.text-orange-high {
  color: #f05436;
}
