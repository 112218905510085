@import 'bootstrap/dist/css/bootstrap.min.css';
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap'); */
/* 
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,600;1,600&display=swap'); */

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 31, 2023 */

.revolution_title_width {
  width: 100%;
}

.revolution_title {
  width: 100%;
  font-size: 70px;
  color: white;
  text-shadow:
    0px 0px 5px #f05436,
    0 0 1em #f05436,
    0 0 0.1em #f05436;
  line-height: 84px;
  position: relative;
  /* -webkit-text-stroke: 0.5px #ed6a4f; */
}

.revolution_title::after {
  -webkit-text-stroke: 0.5px #ed6a4f;
  text-shadow: none;
  top: 0;
  left: 0;
  position: absolute;
  content: 'Revolutionise Marketing';
}

.child_titles {
  margin-bottom: 45px !important;
}

.child_titles_text {
  color: #f05436;
  font-size: 50px;
  font-weight: 600;
  line-height: 60px;
}

.cards_btn_div {
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(10px, 8rem);
  gap: 20px;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
}

.circle_cards {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: #09091d;
  box-shadow: 0px 0px 10px #f05436;
  border-radius: 16px;
  padding: 18px;
  height: 100px;
  width: 207px;
  max-width: 207px;
}

.card_texts {
  color: white;
  text-shadow: 0px 0px 5px #f05436;
  font-size: 16px;
  letter-spacing: 1.2px;
  font-weight: 200 !important;
  text-transform: uppercase;
  -webkit-text-stroke: 0.5px #ed6a4f;
  /* font-family: 'poppinslight'; */
  margin-bottom: 0px !important;
}

.text_btns_div {
  margin-bottom: 3rem;
}

.text_btns_div_p {
  width: 100%;
  font-weight: 500;
  font-size: 20px;
}

.btns_flex_div {
  display: flex;
}

.trial_btn {
  border-radius: 15px;
  color: #f05436;
  border: 0.5px solid #f05436;
  box-shadow: 0 0 10px 3px #f05436;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1.33px;
  background: white;
  transition: 0.3s;
  margin-right: 20px;
  font-weight: 700;
  text-decoration: none;
  padding: 8px 18px;
}

.trial_btn:hover {
  background-color: #f05436;
  box-shadow: 0 0 10px 3px white;
  color: white;
}

.book_btn {
  border-radius: 15px;
  color: white;
  border: 0px;
  box-shadow: 0 0 10px 3px white;
  font-size: 20px;
  text-transform: uppercase;
  background: #f05436;
  transition: 0.3s;
  font-weight: 700;
  letter-spacing: 1.33px;
  padding: 8px 18px;
  text-decoration: none;
}

.book_btn:hover {
  background-color: white;
  box-shadow: 0 0 10px 3px #f05436;
  color: #f05436;
}

.marketings {
  margin-top: 4rem;
  width: 100%;
}

.marketing_cards {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.marketing_card {
  align-items: center;
  /* font-family: 'poppins'; */
  background-color: #09091d;
  box-shadow: 0 0 10px white;
  border-radius: 20px;
  width: 48%;
  height: auto;
  min-height: 230px;
  display: flex;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 40px;
  gap: 20px;
  justify-content: center;
}

.card_img_div {
  width: 40%;
}

.card_img {
  width: 100%;
}

.details_div {
  width: 50%;
}

.details_div_title {
  font-size: 20px;
  color: #f05436;
  margin-bottom: 30px;
  text-transform: uppercase;
  height: 50px;
  font-weight: 600 !important;
  font-family: 'poppins';
}

.details_div_para {
  font-size: 14px;
  margin-top: 10px;
  color: white;
  font-family: 'Inter';
  font-weight: 600 !important;
}

.more_btn {
  align-items: center;
  background-color: #09091d;
  box-shadow: 0 0 10px white;
  border-radius: 15px;
  padding: 10px;
  color: white;
  border: 0px;
  margin-top: 0px;
  width: 180px;
  font-size: 18px;
  font-family: 'poppins';
  font-weight: 600 !important;
}

.solution_div {
  margin-top: 4rem;
}

.solitions_card_left {
  text-align: center;
  margin: auto;
  width: 80%;
  min-height: 130px !important;
  max-height: 130px !important;
  height: auto;
  display: flex;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  background: rgb(181, 87, 69);
  background: linear-gradient(90deg, rgba(181, 87, 69, 1) 0%, rgba(9, 9, 29, 1) 98%);
  margin-bottom: 70px;
  margin-left: 10px;
}

.solitions_card_right {
  text-align: center;
  margin: auto;
  width: 80%;
  min-height: 130px !important;
  max-height: 130px !important;
  height: auto;
  display: flex;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  background: rgb(181, 87, 69);
  background: linear-gradient(90deg, rgba(181, 87, 69, 1) 0%, rgba(9, 9, 29, 1) 98%);
  margin-bottom: 70px;
  margin-right: 20px;
}

.solitions_btn_div {
  width: 40%;
  border-radius: 15px;
  background-color: #09091d;
  box-shadow: 0px 0px 10px #f05436;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 10px;
  height: auto;
  max-height: 100% !important;
  text-transform: uppercase;
}

.solitions_btn_div_p {
  margin-top: 5px;
  font-size: 16px;
  letter-spacing: 1.2px;
  font-weight: 600 !important;
  font-family: 'poppins';
  color: white;
  /* text-shadow: 4px 4px 105px #f05436; */
  /* text-shadow: #f05436 3px 0 30px; */
  text-shadow:
    0px 0px 5px #f05436,
    0 0 1em #f05436,
    0 0 0.2em #f05436;
  -webkit-text-stroke: 0.5px #ed6a4f;
  justify-content: center !important;
  text-align: center;
  margin: auto;
}

.solitions_details_div {
  width: 70%;
  border-radius: 15px;
  padding: 0px 20px;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.solitions_details_div_para {
  font-size: 14px;
  margin-top: 10px;
  color: white;
  text-align: left;
  font-weight: 600 !important;
  font-family: 'Inter';
}

@media only screen and (max-width: 500px) {
  .cards_btn_div {
    display: block;
  }

  .text_btns_div .text_btns_div_p {
    font-size: 16px;
  }

  .circle_cards {
    width: 100% !important;
    max-width: 100%;
  }

  .btns_flex_div {
    display: flex;
  }

  .cards_btn_div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .card_div {
    width: 45%;
  }

  .card_texts {
    -webkit-text-stroke: 0px !important;
    position: relative;
  }

  .card_texts::after {
    content: attr(data-content);
    position: absolute;
    left: 0;
    top: 0;
    text-shadow: none;
    -webkit-text-stroke: 0.5px #ed6a4f;
  }
}

@media only screen and (max-width: 676px) {
  .solitions_cards {
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: minmax(10px, 8rem);
    gap: 20px;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
  }

  .solitions_btn_div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px !important;
    min-height: 80px;
  }

  .solitions_card_left,
  .solitions_card_right {
    width: 100%;
    align-items: center;
    text-align: center;
    margin: auto;
    display: block;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 0px !important;
    background: rgb(9, 9, 29);
    background: linear-gradient(0deg, rgba(9, 9, 29, 1) 0%, rgba(181, 87, 69, 1) 89%);
    margin-bottom: 20px;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .solitions_card_right {
    margin-right: 0px;
    width: 100%;
  }

  .solitions_details_div {
    width: 100%;
  }
}

@media only screen and (max-width: 1020px) {
  .details_div_title {
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .marketing_cards {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .solitions_card_left {
    min-height: 140px !important;
    max-height: 140px !important;
  }

  .solitions_card_right {
    min-height: 180px !important;
    max-height: 180px !important;
  }
}

@media only screen and (max-width: 980px) {
  .details_div_title {
    margin-bottom: 60px;
  }

  .solitions_card_left,
  .solitions_card_left {
    min-height: 160px !important;
    max-height: 160px !important;
  }
}

@media only screen and (max-width: 790px) {
  .more_btn_div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .more_btn {
    width: 50%;
  }

  .marketing_cards {
    /* flex-wrap: wrap; */
    display: block;
    /* justify-content: space-between; */
    /* align-items: center; */
  }

  .marketing_card {
    width: 100%;
  }

  .card_img_div {
    width: 200px;
  }

  .details_div_title {
    margin-bottom: 0px;
  }

  .solitions_card_left,
  .solitions_card_left {
    min-height: 200px !important;
    max-height: 200px !important;
  }
}

@media only screen and (max-width: 500px) {
  .revolution_title {
    width: 80%;
    font-size: 50px;
    line-height: 60px;
  }

  .child_titles_text {
    font-size: 35px;
    line-height: 1;
  }

  .marketing_card {
    display: block;
  }

  .card_img_div {
    width: 100%;
  }

  .details_div {
    width: 100%;
    margin-top: 30px;
    padding-left: 20px;
  }

  .details_div_title {
    font-size: 20px;
    height: auto;
    margin-bottom: 0px;
  }

  .details_div_para {
    font-size: 13px;
    margin-top: 10px;
    color: white;
  }
}

@media only screen and (max-width: 380px) {
  .revolution_title {
    font-size: 40px;
  }

  .child_titles_text {
    font-size: 30px;
    line-height: 1.1;
  }

  .trial_btn {
    margin-bottom: 20px;
  }
}
