@import 'bootstrap/dist/css/bootstrap.min.css';

.width_about_get_start_container {
  width: 93%;
  margin-top: 500px;
}

@media only screen and (min-width: 1400px) {
  .width_about_get_start_container {
    width: 1300px !important;
    margin-top: 100px;
  }
}
