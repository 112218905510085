.myaccount_companies-container {
  display: grid;
  gap: 40px;
}

.myaccount_companies-container .myaccount_companies-title {
  font-size: 18px;
  color: white;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 2px;
  font-weight: 500;
}

.myaccount_companies-card-wrapper {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr) !important;
  gap: 40px 80px !important;
}

.myaccount_companies-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 180px;
  background-color: #09091d;
  box-shadow: 0px 0px 7px white;
  border-radius: 16px;
  padding: 25px 20px 10px 20px;
}

.myaccount_companies-card-head h5 {
  color: white;
  letter-spacing: 2px;
  font-size: 9px;
  margin-bottom: 1px;
  font-family: 'poppins';
}

.myaccount_companies-card-head h4 {
  color: #f05436;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1.5px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  margin-top: 3px;
  font-family: 'poppins';
}

.myaccount_companies-card-footer {
  display: grid;
}

.myaccount_companies-card-collaborator {
  display: flex;
  gap: 5px;
}

.myaccount_companies-card-collaborator img {
  height: 33px;
  width: 31px;
}

.myaccount_companies-card-collaborator p {
  color: white;
  font-size: 10px;
  font-family: 'poppins';
  margin: auto 0;
}

.myaccount_companies-card-action {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.myaccount_companies-card-action .edit-btn {
  margin-left: auto;
  color: white;
  background-color: #f05436;
  border-radius: 10px;
  outline: none;
  border: none;
  font-size: 10px;
  padding: 0.5rem 1.6rem;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
}

.myaccount_companies-card-action .collaborator-btn {
  background-color: #09091d;
  border: none;
}

.myaccount_companies-card-action .delete-btn {
  color: white;
  font-size: 10px;
  font-family: 'Poppins', sans-serif;
  background-color: #09091d;
  border: none;
}

.myaccount_companies-card.create-card .myaccount_companies-card-title {
  margin-top: 20px;
  color: #f05436;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  text-align: center;
}

.myaccount_companies-card.create-card .myaccount_companies-card-icon {
  margin: auto;
  margin-top: 10px;
}

.myaccount_companies-card.create-card .myaccount_companies-card-icon svg {
  border-radius: 50%;
  padding: 17px;
  box-shadow: 0px 0px 18px #f05436;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f05436;
  text-align: center;
  cursor: pointer;
}

@media only screen and (max-width: 1200px) {
  .myaccount_companies-card-wrapper {
    gap: 40px 40px !important;
  }
}

@media only screen and (max-width: 991px) {
  .myaccount_companies-card-wrapper {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media only screen and (max-width: 750px) {
  .myaccount_companies-card-wrapper {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
