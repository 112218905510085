@import 'bootstrap/dist/css/bootstrap.min.css';

.selectBox {
  /* width: 12%; */
  border: none;
  border: 0px;
  border-radius: 0%;
  background-color: #09091d;
  color: aliceblue;
  /* text-align: right; */
}

.dropDownItem {
  font-size: 16px;
}

.dropdownmanage {
  margin-right: 1rem !important;
}

@media only screen and (max-width: 767px) {
  .selectBox {
    display: none;
  }
}
