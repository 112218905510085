.business_areas_wrapper {
    border-radius: 40px;
    min-height: unset;
    box-shadow: 0 0 18px white;
    background-color: #09091d;
    text-align: left;
    padding: 50px 63px;
    margin-bottom: 200px;

}

.business_areas {
    font-family: "Poppins";
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 17px;
    padding: 0;
    margin-top: 51px;
}

.business_areas-title {
    font-size: 40px;
    color: #f05436;
}

.business_areas li {
    display: flex;
    gap: 10px;
    align-items: center;
    text-decoration: underline;
    text-decoration-color: white;
    text-underline-offset: 4px;
    font-family: 'poppinsregular';
}

@media (max-width:992px) {
    .business_areas_wrapper {
        padding: 40px;
        margin-bottom: 120px;
    }

    .business_areas {
        grid-template-columns: repeat(2, 1fr);
    }

    .business_areas-title {
        font-size: 20px;
    }

    .business_areas li {
        font-size: 14px;
    }
}

@media (max-width:767px) {
    .business_areas_wrapper {
        padding: 33px;
        margin-bottom: 62px;
    }

    .business_areas {
        grid-template-columns: repeat(2, 1fr);
        margin-top: 20px;
    }

    .business_areas li {
        font-size: 12px;
    }

    .business_areas li img {
        width: 15px;
    }
}